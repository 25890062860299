<template>
      <div id="plan-options">
            <section class="section bottom-content">
                  <div class="grid-container">
                        <BackButton link="/choose-plan"/>
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb32">
                              <div class="cell large-9">
                                    <h1>Both HMO and HRA Plan Options feature flexibility and savings</h1>
                              </div>
                              <div class="cell large-12">
                                    <div class="grid-x grid-padding-x">
                                          <div class="cell large-10">
                                                <p class="navy-blue-text fs18 fw400">All Anthem Plan Options include access to a wide range of personalized solutions designed to help you and your family have confidence and control in your health.</p>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x mb80">
                              <div class="cell medium-4 mb24">
                                    <img src="img/people-icon.png" width="50" class="mb16"/>
                                    <h5 class="navy-blue-text mb8 fs18 fw600">Find your best care</h5>
                                    <ul>
                                          <li>Direct access to specialists, no referrals needed</li>
                                          <li>24/7 access to in-network providers via LiveHealth Online<sup>1</sup></li>
                                          <li>Access to our national network of doctors, and even some doctors outside of the U.S.</li>
                                    </ul>
                              </div>
                              <div class="cell medium-4 mb24">
                                    <img src="img/bank-icon.png" width="50" class="mb16"/>
                                    <h5 class="navy-blue-text mb8 fs18 fw600">Create your savings</h5>
                                    <ul>
                                          <li>You and your covered spouse can each earn up to 480 points (a total of up to 960 points) by completing certain health actions. You can redeem these points as credits to apply toward eligible medical and pharmacy expenses<sup>2</sup></li>
                                          <li>You can rollover unused credits from your 2024 SHBP health plan, if you have any remaining</li>
                                    </ul>
                              </div>
                              <div class="cell medium-4 mb24">
                                    <img src="img/weight-icon.png" width="50" class="mb16"/>
                                    <h5 class="navy-blue-text mb8 fs18 fw600">Maintain your health</h5>
                                    <ul>
                                          <li>Eligible preventive care is covered at 100% when you see an in-network provider<sup>3</sup></li>
                                          <li>Anthem health experts are available at no extra cost to help you and covered members of your family manage chronic conditions</li>
                                          <li>On-the-go access to your health plan via the Sydney<sup>SM</sup> Health mobile app</li>
                                    </ul>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x mb40">
                              <div class="cell medium-12">
                                    <p class="fs12">1 LiveHealth Online is the trade name of Health Management Corporation, a separate company providing telehealth services on behalf of Anthem Blue Cross and Blue Shield. 2 Completing your well-being incentive actions with Sharecare will earn you points. You can choose to redeem these points for well-being incentive credits to use on covered medical and pharmacy expenses or the Visa Prepaid Card option. Visit <a href="https://BeWellSHBP.com" class="navy-blue-text fw400" target="_blank">BeWellSHBP.com</a> for more information. 3 Services must be properly coded as preventive care under the Patient Protection and Affordable Care Act.</p>
                              </div>
                        </div>
                  </div>
            </section>
            <NextButton link="/compare"/>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import NextButton from "../components/NextButton"
      export default {
            name: "SHBPPlanOptions",
            data (){
                  return{
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {
                  BackButton,
                  NextButton
            }
      }
</script>

<style lang="scss">
      #plan-options{
            .back-btn{
                  left: 0.65rem;
            }
           .bottom-content{
                 margin-top: 68px;
                 padding: 100px 0px 80px 0px;
                 @media only screen and (max-width:640px){
                        padding: 40px 20px 80px 20px;
                  }
                 h1{
                        color: #002E58;
                       margin-top: 3rem;
                       margin-bottom: 4rem;
                       @media only screen and (max-width:640px){
                              margin-top: 4rem;
                              margin-bottom: 2rem;
                        }
                 }
                 ul{
                       li{
                             margin-bottom: 8px;
                       }
                 }
           }
            img{
                  filter: invert(53%) sepia(87%) saturate(3205%) hue-rotate(330deg) brightness(94%) contrast(100%);
            }
      }
</style>

import {createStore} from "vuex";

export default createStore({
  state: {
    // put variables and collections here
    firstName: "",
    coverage: "",
    currMember: "",
    keepPlan: "",
    planSelect: "",
    planOptions: "",
    changedOptions: "",
    routeName: [],
    routePath: []
  },
  getters: {
    // put sychronous functions for changing state e.g. add, edit, delete
    getName(state){
      return state.firstName
    },
    getCoverage(state){
      return state.coverage
    },
    getCurrMember(state){
          return state.currMember
    },
    getKeepPlan(state){
          return state.keepPlan
    },
    getPlanOptions(state){
          return state.planOptions
    },
    getChangedOptions(state){
          return state.changedOptions
    }
  },
  mutations: {
    // put sychronous functions for changing state e.g. add, edit, delete
    changeName (state, payload) {
      state.firstName = payload
    },
    changeCoverage (state, payload) {
      state.coverage = payload
    },
    changeCurrMember (state, payload) {
          state.currMember = payload
    },
    changeKeepPlan (state, payload) {
          state.keepPlan = payload
    },
    changePlan (state, payload) {
          state.planSelect = payload
    },
    changePlanOptions (state, payload) {
          state.planOptions = payload
    },
    changeChangedOptions (state, payload) {
          state.changedOptions = payload
    },
    updateRouteName(state, payload){
          state.routeName.push(payload)
    },
    updateRoutePath(state, payload){
          state.routePath.push(payload)
    }
  },
  actions: {},
  modules: {},
});
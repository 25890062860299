<template>
      <div id="checklist">
            <section class="section hero">
                  <div class="grid-container">
                        <BackButton link="/welcome-to-anthem"/>
                        <div id="hero-content" class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn checklist-hero-content">
                              <div class="cell large-12">
                                    <h1>Your checklist for 2025</h1>
                              </div>
                        </div>
                  </div>
            </section>
            <section class="section bottom-content">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell large-5 first-column">
                                    <div v-if="currMember != 'New Prospective'">
                                          <p class="fs18 navy-blue-text fw400">If you don't make any changes to your current Plan Option and tier, your plan will automatically roll over for 2025. If you do plan to change anything, follow this checklist.</p>
                                    </div>
                                    <p>This year, the Open Enrollment selection period is <br/><a href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20241015%2F20241108&details=This%20year%2C%20the%20Open%20Enrollment%20selection%20period%20is%20October%2015%2C%202024%20-%0ANovember%208%2C%202024.%20&text=Anthem%20Open%20Enrollment" class="navy-blue-text underline" target="_blank" @click="track('Link', 'Add To Calendar - Checklist')">October 15 - November 8, 2024</a></p>
                              </div>
                              <div class="cell large-4 medium-offset-3 second-column">
                                    <div class="checklist-box one">
                                          <h5>Check</h5>
                                          <p>your premium rates for Plan Options at <a href="https://shbp.georgia.gov" class="navy-blue-text underline" target="_blank" @click="track('Link', 'shbp.georgia.gov - Checklist')">shbp.georgia.gov</a> to see your monthly payment.</p>
                                    </div>
                                    <div class="checklist-box two">
                                          <h5>Review</h5>
                                          <p>the information here to compare Anthem's Plan Options. If you need assistance, use the enrollment resources available at: <a href="https://anthem.com/shbp" class="navy-blue-text underline" target="_blank" @click="track('Link', 'anthem.com/shbp - Checklist')">anthem.com/shbp</a>.</p>
                                    </div>
                                    <div class="checklist-box three">
                                          <h5>Log-in</h5>
                                          <p>at <a href="https://mySHBPga.adp.com" class="navy-blue-text underline" target="_blank" @click="track('Link', 'mySHBPga.adp.com - Checklist')">mySHBPga.adp.com</a> to select your Plan Option and tier during Open Enrollment if you want to make a change.</p>
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
            <NextButton link="/choose-plan"/>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import NextButton from "../components/NextButton"
      import gaEventMixin from "../mixins/gaEventMixin"
      export default {
            name: "SHBPChecklist",
            data (){
                  return{
                        currMember:this.$store.state.currMember
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {
                  BackButton,
                  NextButton
            },
            mixins: [gaEventMixin]
      }
</script>

<style lang="scss">
      #checklist{
            .hero{
                  background-image: none;
                  background-color: #ffffff;
                  .checklist-hero-content{
                        min-height: 174px;
                        @media only screen and (max-width:640px){
                              min-height: 160px;
                              padding-bottom: 0px;
                        }
                  }
                  @media only screen and (max-width:640px){
                        padding-bottom: 0px;
                  }
            }
            .bottom-content{
                  padding-top: 0px;
                  .first-column{
                        @media only screen and (max-width:640px){
                              margin-bottom: 24px;
                        }
                  }
                  .second-column{
                        @media only screen and (max-width:640px){
                              padding-left: 3.5rem;
                        }
                  }
            }
            .checklist-box{
                  position: relative;
                  h5{
                        color: #002E58;
                        font-size: 1.125rem !important;
                        font-weight: 600;
                        margin-bottom: 0.125rem;
                  }
                  p{
                        font-size: 1rem;
                        font-weight: 400;
                  }
                  &:before{
                        content: "";
                        text-align: center;
                        color: #F2695A;
                        background-color: #ffffff;
                        font-weight: 600;
                        position: absolute;
                        top: 0;
                        left: -43px;
                        border-radius: 50%;
                        border: 2px solid #F2695A;
                        width: 28px;
                        height: 28px;
                        font-size: 1rem;
                        z-index: 1;
                  }
                  &:after{
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 148px;
                        background-color: #F2695A;
                        top: 0;
                        left: -29px;
                  }
                  &.one{
                        &:before{
                              content: "1";
                        }
                  }
                  &.two{
                        &:before{
                              content: "2";
                        }
                  }
                  &.three{
                        &:before{
                              content: "3";
                        }
                        &:after{
                              display: none;
                        }
                  }
            }
      }
</style>

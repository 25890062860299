<template>
      <div id="member">
            <section class="section hero">
                  <div class="grid-container">
                        <BackButton link="/name" color="white" />
                        <div id="hero-content"
                              class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell medium-6">
                                    <h1>Let's find the right information for you</h1>
                              </div>
                              <div class="cell medium-4 medium-offset-2">
                                    <p>Help us make this experience fit your expectations and needs by answering a few
                                          basic questions.</p>
                              </div>
                        </div>
                  </div>
            </section>
            <section class="section bottom-content">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn">
                              <div class="cell medium-5">
                                    <h3 class="navy-blue-text fw400">Are you:</h3>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn mb8">
                              <div class="cell medium-5">
                                    <div class="grid-x grid-padding-x">
                                          <label class="radio cell auto">
                                                <div class="grid-x grid-padding-x">
                                                      <div class="cell shrink">
                                                            <input type="radio" value="Current Member" v-model="currMember" />
                                                      </div>
                                                      <div class="cell auto">
                                                            <span>current SHBP Anthem member?</span>
                                                      </div>
                                                </div>
                                          </label>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn">
                              <div class="cell medium-5">
                                    <div class="grid-x grid-padding-x">
                                          <label class="radio cell auto">
                                                <div class="grid-x grid-padding-x">
                                                      <div class="cell shrink">
                                                            <input type="radio" value="New Prospective" v-model="currMember" />
                                                      </div>
                                                      <div class="cell auto">
                                                            <span>a new, prospective SHBP Anthem member?</span>
                                                      </div>
                                                </div>
                                          </label>
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
            <section id="check-btn-bottom">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x">
                              <div class="cell medium-12">
                                    <button class="button check-btn large" @click="storeData()">Continue</button>
                              </div>
                        </div>
                  </div>
            </section>

      </div>
</template>

<script>
import BackButton from "../components/BackButton"
import gaEventMixin from "../mixins/gaEventMixin"
export default {
      name: "SHBPMember",
      data() {
            return {
                  currMember: this.$store.state.currMember
            }
      },
      methods: {
            storeData() {
                  const member = this.currMember
                  if ((!member)) {
                        let message = 'Please make a choice below.'
                        this.$swal({
                              html: message,
                              showCancelButton: false
                        })
                  }
                  else {
                        this.$store.commit("changeCurrMember", member)
                        //this.track("Choice", member + " Coverage")
                        if (member == "New Prospective") this.$router.push("/welcome-new")
                        else this.$router.push("/welcome-back-current")
                        localStorage.setItem("antCurrMember", member)
                  }
            }
      },
      mounted() {
            window.scrollTo(0, 0)
      },
      components: { BackButton },
      mixins: [gaEventMixin]
}
</script>

<style lang="scss">
#member {
      .hero {
            background-image: none;

            h1,
            p {
                  color: #ffffff !important;
            }

            #hero-content {
                  h1 {
                        @media only screen and (max-width:640px) {
                              margin-top: 1.5rem;
                        }
                  }
            }
      }

      h3 {
            color: #0E63AF;
            font-size: 1.125rem;
            font-weight: 400;
            margin-bottom: 0.5rem;
      }
}
</style>

<template>
      <div id="welcome-back">
            <section class="section hero">
                  <div class="grid-container">
                        <BackButton link="/welcome-back-current" color="white"/>
                        <div id="hero-content" class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell medium-6">
                                    <h1>Welcome back, {{ firstName }}</h1>
                              </div>
                              <div class="cell medium-4 medium-offset-2">
                                    <p>Help us make this experience fit your expectations and needs by answering a few basic questions.</p>
                              </div>
                        </div>
                  </div>
            </section>
            <section class="section bottom-content">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell large-5 first-column">
                                    <p class="fs18 navy-blue-text fw400">This guide will allow you to review all Anthem Plan Options for 2025.</p>
                              </div>
                              <div class="cell large-5 medium-offset-2 second-column">
                                    <p class="bullet">The estimated length of your experience on this site will be: <span class="navy-blue-text">8 minutes</span></p>
                              </div>
                        </div>
                  </div>
            </section>
            <NextButton link="/considering-coverage"/>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import NextButton from "../components/NextButton"
      export default {
            name: "SHBPWelcomeBack",
            data (){
                  return{
                        firstName: this.$store.state.firstName,
                  }
            },
            components: {
                  BackButton,
                  NextButton
            }
      }
</script>

<style lang="scss">
      #welcome-back{
            .hero{
                  background-image: none;
                  h1, p{
                        color: #ffffff !important;
                  }
                  #hero-content{
                        h1{
                              margin-bottom: 4.5rem;
                              @media only screen and (max-width:640px){
                                    margin-top: 1.5rem;
                                    margin-bottom: 0;
                              }
                        }
                  }
            }
      }
</style>

<template>
      <div id="considering-coverage">
            <section class="section hero">
                  <div class="grid-container">
                        <BackButton link="/welcome-back" color="white"/>
                        <div id="hero-content" class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell medium-6">
                                    <h1>Let's find the right information for you</h1>
                              </div>
                              <div class="cell medium-4 medium-offset-2">
                                    <p>Help us make this experience fit your expectations and needs by answering a few basic questions.</p>
                              </div>
                        </div>
                  </div>
            </section>
            <section class="section bottom-content">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn">
                              <div class="cell medium-5">
                                    <h3 class="navy-blue-text fs18 fw400">Who are you considering coverage for?</h3>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn mb8">
                              <div class="cell medium-5">
                                    <div class="grid-x grid-padding-x">
                                          <label class="radio cell auto">
                                                <div class="grid-x grid-padding-x">
                                                      <div class="cell shrink">
                                                            <input type="radio" value="You" v-model="coverage"/>
                                                      </div>
                                                      <div class="cell auto">
                                                            <span>You</span>
                                                      </div>
                                                </div>
                                          </label>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn mb8">
                              <div class="cell medium-5">
                                    <div class="grid-x grid-padding-x">
                                          <label class="radio cell auto">
                                                <div class="grid-x grid-padding-x">
                                                      <div class="cell shrink">
                                                            <input type="radio" value="You-Spouse" v-model="coverage"/>
                                                      </div>
                                                      <div class="cell auto">
                                                            <span>You + Spouse</span>
                                                      </div>
                                                </div>
                                          </label>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn mb8">
                              <div class="cell medium-5">
                                    <div class="grid-x grid-padding-x">
                                          <label class="radio cell auto">
                                                <div class="grid-x grid-padding-x">
                                                      <div class="cell shrink">
                                                            <input type="radio" value="You-Child(ren)" v-model="coverage"/>
                                                      </div>
                                                      <div class="cell auto">
                                                            <span>You + Child(ren)</span>
                                                      </div>
                                                </div>
                                          </label>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn">
                              <div class="cell medium-5">
                                    <div class="grid-x grid-padding-x">
                                          <label class="radio cell auto">
                                                <div class="grid-x grid-padding-x">
                                                      <div class="cell shrink">
                                                            <input type="radio" value="You-Family" v-model="coverage"/>
                                                      </div>
                                                      <div class="cell auto">
                                                            <span>You + Family</span>
                                                      </div>
                                                </div>
                                          </label>
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
            <section id="check-btn-bottom">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x">
                              <div class="cell medium-12">
                                    <button class="button check-btn large" @click="storeData()">Next</button>
                              </div>
                        </div>
                  </div>
            </section>
            
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import gaEventMixin from "../mixins/gaEventMixin"
      export default {
            name: "SHBPConsideringCoverage",
            data (){
                  return{
                        coverage: this.$store.state.coverage
                  }
            },
            methods:{
                  storeData(){
                        const coverage = this.coverage
                        if((!coverage) ){
                              let message = 'Please make a choice below.'
                              this.$swal({
                                    html: message,
                                    showCancelButton: false
                              })
                        }
                        else{
                              this.$store.commit('changeCoverage', coverage)
                              //this.track("Choice", coverage + " Coverage")
                              this.$router.push('/keeping-plan')
                              localStorage.setItem("antCoverage", coverage)
                        }
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {BackButton},
            mixins: [gaEventMixin]
      }
</script>

<style lang="scss">
      #considering-coverage{
            .hero{
                  background-image: none;
                  h1, p{
                        color: #ffffff !important;
                  }
                  #hero-content{
                        h1{
                              @media only screen and (max-width:640px){
                                    margin-top: 1.5rem;
                              }
                        }
                  }
            }
      }
</style>

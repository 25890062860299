<template>
      <div id="total-health">
            <section class="section bottom-content">
                  <div class="grid-container">
                        <BackButton link="/dermatologist"/>
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb48">
                              <div class="cell large-10">
                                    <h1>Total Health,<br class="hide-for-medium"/> Total You</h1>
                              </div>
                              <div class="cell large-12">
                                    <div class="grid-x grid-padding-x">
                                          <div class="cell large-9">
                                                <p class="fs18">Have someone by your side to help you achieve your health goals. Total Health, Total You offers a holistic clinical management approach to address all your healthcare needs.</p>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb24">
                              <div class="cell medium-9">
                                    <div class="grid-x grid-padding-x align-top mb24">
                                          <div class="cell large-12">
                                                <p class="fs18 fw600">A team that delivers</p>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-top animate__animated animate__fadeIn mb48">
                              <div class="cell medium-9">
                                    <div class="grid-x grid-padding-x align-top">
                                          <div class="cell small-6 medium-3 mb40">
                                                <img src="img/rn-icon.png" class="mb8" width="37"/>
                                                <h5 class="navy-blue-text fs16">Registered<br/>nurses </h5>
                                          </div>
                                          
                                          <div class="cell small-6 medium-3 mb40">
                                                <img src="img/group-icon.png" class="mb8" width="38"/>
                                                <h5 class="navy-blue-text fs16">Social <br/>workers</h5>
                                          </div>
                                          <div class="cell small-6 medium-3 mb40">
                                                <img src="img/weight-icon.png" class="mb8" width="41"/>
                                                <h5 class="navy-blue-text fs16">Exercise<br/> physiologists</h5>
                                          </div>
                                          <div class="cell small-6 medium-3 mb40">
                                                <img src="img/lungs-icon.png" class="mb8" width="55"/>
                                                <h5 class="navy-blue-text fs16">Respiratory<br/> therapists</h5>
                                          </div>
                                    </div>
                                    <div class="grid-x grid-padding-x align-top">
                                          <div class="cell small-6 medium-3 mb40">
                                                <img src="img/play-icon.png" class="mb8" width="39"/>
                                                <h5 class="navy-blue-text fs16">Health<br/>coaches</h5>
                                          </div>
                                          <div class="cell small-6 medium-3 mb40">
                                                <img src="img/scale-icon.png" class="mb8" width="41"/>
                                                <h5 class="navy-blue-text fs16">Registered<br/> dietitians</h5>
                                          </div>
                                          <div class="cell small-6 medium-3 mb40">
                                                <img src="img/rx-icon.png" class="mb8" width="36"/>
                                                <h5 class="navy-blue-text fs16">Pharmacists</h5>
                                          </div>
                                    </div>
                              </div>
                              <div class="cell medium-3">
                                    <div class="side-content">
                                          <p class="fs16 bullet ml16">To learn more, call<br/> <span class="navy-blue-text">866-901-0746</span>, available from 8 a.m. to 6 p.m. ET, Monday through Friday. </p>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-top mb80">
                              <div class="cell medium-8">
                                    <div class="waiver-box">
                                          <img src="img/pill-icon.png" class="mb8" width="34"/>
                                          <h5 class="navy-blue-text fs18 mb8">Co-Pay/Co-Insurance Waiver Program</h5>
                                          <p>Eligible SHBP members can receive certain prescription drugs for asthma, diabetes, coronary artery disease (CAD) or medications for addiction treatment at no additional cost.</p>
                                    </div>
                              </div>
                        </div>
                        <!--<div class="grid-x grid-padding-x">
                              <div class="cell small-6">
                                    <router-link to="/intro" class="float-left fw600 fs18 return-link" @click="track('Link', 'Return to Guide - Total Health')">Return to guide</router-link>
                              </div>
                        </div>-->
                  </div>
            </section>
            <NextButton link="/rewards"/>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import NextButton from "../components/NextButton"
      import gaEventMixin from "../mixins/gaEventMixin"
      export default {
            name: "SHBPTotalHealth",
            data (){
                  return{
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {
                  BackButton,
                  NextButton
            },
            mixins: [gaEventMixin]
      }
</script>

<style lang="scss">
      #total-health{
            .back-btn{
                  left: 0.65rem;
            }
            .bottom-content{
                 margin-top: 68px;
                 padding: 100px 0px 80px 0px;
                 @media only screen and (max-width:640px){
                        padding: 40px 20px 80px 20px;
                  }
                 h1{
                        color: #002E58;
                       margin-top: 3rem;
                       margin-bottom: 4rem;
                       @media only screen and (max-width:640px){
                              margin-top: 4rem;
                              margin-bottom: 2rem;
                        }
                 }
                 h5{
                       margin-bottom: 0.5rem;
                 }
                 .side-content{
                        //border-left:solid 4px #F05349;
                        padding-left: 16px;
                        padding: 8px 0px 8px 16px;
                        p{
                              margin-bottom: 0px !important;
                        }
                  }
                  .waiver-box{
                        background-color: #E1EDFF;
                        padding: 24px;
                        border-radius: 20px;
                  }
                  img{
                        filter: invert(53%) sepia(87%) saturate(3205%) hue-rotate(330deg) brightness(94%) contrast(100%);
                  }
           }
           .return-link{
                  position: relative;
                  padding-left: 20px;
                  &:before{
                        content: "";
                        background-image: url("~@/assets/img/arrow-left-icon.png");
                        background-size: 15px 13px;
                        width: 15px;
                        height: 13px;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                  }
            }
      }
</style>

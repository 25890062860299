<template>
      <div id="benefits-current">
            <section class="section bottom-content">
                  <div class="grid-container">
                        <BackButton link="/checklist-current"/>
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb24">
                              <div class="cell large-10">
                                    <h1>2025 Benefits at a glance</h1>
                              </div>
                              <div class="cell large-12">
                                    <div class="grid-x grid-padding-x">
                                          <div class="cell large-10">
                                                <p class="navy-blue-text fs18 fw400">The tables here show you some high-level costs according to the plan and coverage you choose. For more detailed benefit information, view the Summary Plan Descriptions at <a href="https://shbp.georgia.gov" class="navy-blue-text underline" target="_blank" @click="track('Link', 'shbp.georgia.gov - Benefits')">shbp.georgia.gov</a>.</p>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-bottom">
                              <div class="cell medium-12">
                                    <div class="grid-x grid-padding-x align-middle mb40">
                                          <div class="cell shrink">
                                                <p class="mb0">Choose coverage:</p>
                                          </div>
                                          <div class="cell shrink">
                                                <select class="mb0" v-model="benefitSelection">
                                                      <option v-for="benefit in benefitOptions" v-bind:value="benefit.value" :key="benefit.value">
                                                            {{ benefit.text }}
                                                      </option>
                                                </select>
                                          </div>
                                    </div>
                                    <div class="grid-x grid-padding-x mb48">
                                          <div class="cell medium-12 table-container">
                                                <transition name="table-anim" enter-active-class="animate__animated animate__fadeIn animate__faster" leave-active-class="animate__animated animate__fadeOut animate__faster" mode="out-in">
                                                      <table v-if="benefitSelection == 'You'">
                                                            <thead>
                                                                  <tr>
                                                                        <th width="30%">&nbsp;</th>
                                                                        <th width="10%" class="navy-blue-text">HMO<sup>1</sup></th>
                                                                        <th width="20%" colspan="2" class="navy-blue-text">HRA<br/>Gold<sup>1</sup></th>
                                                                        <th width="20%" colspan="2" class="navy-blue-text">HRA<br/>Silver<sup>1</sup></th>
                                                                        <th width="20%" colspan="2" class="navy-blue-text">HRA<br/>Bronze<sup>1</sup></th>
                                                                  </tr>
                                                            </thead>
                                                            <tbody>
                                                                  <tr>
                                                                        <td class="freeze-row" style="background-color:#ffffff;"><h5>Network</h5></td>
                                                                        <td class="hmo-bg-blue freeze-row"><strong>IN</strong></td>
                                                                        <td class="hra-bg-gold freeze-row"><strong>IN</strong></td>
                                                                        <td class="hra-bg-gold freeze-row"><strong>OUT</strong></td>
                                                                        <td class="hra-bg-silver freeze-row"><strong>IN</strong></td>
                                                                        <td class="hra-bg-silver freeze-row"><strong>OUT</strong></td>
                                                                        <td class="hra-bg-bronze freeze-row"><strong>IN</strong></td>
                                                                        <td class="hra-bg-bronze freeze-row"><strong>OUT</strong></td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="first">
                                                                              <h5>Deductible <span class="info-circle" @click="infoPop('Deductible', 'The amount you’re responsible for paying each plan year before your plan begins to cover its portion of the covered cost')"></span></h5>
                                                                        </td>
                                                                        <td class="hmo-bg-blue">$1,300</td>
                                                                        <td class="hra-bg-gold">$1,500</td>
                                                                        <td class="hra-bg-gold">$3,000</td>
                                                                        <td class="hra-bg-silver">$2,000</td>
                                                                        <td class="hra-bg-silver">$4,000</td>
                                                                        <td class="hra-bg-bronze">$2,500</td>
                                                                        <td class="hra-bg-bronze">$5,000</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="second">
                                                                              <h5>Co-insurance <span class="info-circle" @click="infoPop('Co-insurance', 'The percentage you pay for the cost of covered health expenses after meeting your deductible.')"></span></h5>
                                                                        </td>
                                                                        <td class="hmo-bg-blue">&nbsp;</td>
                                                                        <td class="hra-bg-gold">&nbsp;</td>
                                                                        <td class="hra-bg-gold">&nbsp;</td>
                                                                        <td class="hra-bg-silver">&nbsp;</td>
                                                                        <td class="hra-bg-silver">&nbsp;</td>
                                                                        <td class="hra-bg-bronze">&nbsp;</td>
                                                                        <td class="hra-bg-bronze">&nbsp;</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="text-left">Plan Pays</td>
                                                                        <td class="hmo-bg-blue">80% after deductible</td>
                                                                        <td class="hra-bg-gold">85% after deductible</td>
                                                                        <td class="hra-bg-gold">60% after deductible</td>
                                                                        <td class="hra-bg-silver">80% after deductible</td>
                                                                        <td class="hra-bg-silver">60% after deductible</td>
                                                                        <td class="hra-bg-bronze">75% after deductible</td>
                                                                        <td class="hra-bg-bronze">60% after deductible</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="text-left">Member Pays</td>
                                                                        <td class="hmo-bg-blue">20% after deductible</td>
                                                                        <td class="hra-bg-gold">15% after deductible</td>
                                                                        <td class="hra-bg-gold">40% after deductible</td>
                                                                        <td class="hra-bg-silver">20% after deductible</td>
                                                                        <td class="hra-bg-silver">40% after deductible</td>
                                                                        <td class="hra-bg-bronze">25% after deductible</td>
                                                                        <td class="hra-bg-bronze">40% after deductible</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="first">
                                                                              <h5>Out-of-pocket maximum<sup>2</sup> <span class="info-circle" @click="infoPop('Out-of-pocket maximum', 'The most you’ll have to pay in a plan year for covered medical expenses, including deductibles, co-pays (HMO plan only), co-insurance, and pharmacy costs. If you reach the out-of-pocket maximum, your eligible expenses are covered 100% by the plan for the remainder of the plan year.')"></span></h5>
                                                                        </td>
                                                                        <td class="hmo-bg-blue">$4,000</td>
                                                                        <td class="hra-bg-gold">$4,000</td>
                                                                        <td class="hra-bg-gold">$8,000</td>
                                                                        <td class="hra-bg-silver">$5,000</td>
                                                                        <td class="hra-bg-silver">$10,000</td>
                                                                        <td class="hra-bg-bronze">$6,000</td>
                                                                        <td class="hra-bg-bronze">$12,000</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="second">
                                                                              <h5>Medical</h5>
                                                                        </td>
                                                                        <td class="hmo-bg-blue">&nbsp;</td>
                                                                        <td class="hra-bg-gold">&nbsp;</td>
                                                                        <td class="hra-bg-gold">&nbsp;</td>
                                                                        <td class="hra-bg-silver">&nbsp;</td>
                                                                        <td class="hra-bg-silver">&nbsp;</td>
                                                                        <td class="hra-bg-bronze">&nbsp;</td>
                                                                        <td class="hra-bg-bronze">&nbsp;</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="text-left">Preventive care<sup>3</sup></td>
                                                                        <td class="hmo-bg-blue">100%</td>
                                                                        <td class="hra-bg-gold">100%</td>
                                                                        <td class="hra-bg-gold">Not covered</td>
                                                                        <td class="hra-bg-silver">100%</td>
                                                                        <td class="hra-bg-silver">Not covered</td>
                                                                        <td class="hra-bg-bronze">100%</td>
                                                                        <td class="hra-bg-bronze">Not covered</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="text-left">Diagnostic breast services (mammogram, ultrasound and MRI)</td>
                                                                        <td class="hmo-bg-blue">100%</td>
                                                                        <td class="hra-bg-gold">100%</td>
                                                                        <td class="hra-bg-gold smaller-font">Co-insurance after deductible</td>
                                                                        <td class="hra-bg-silver">100%</td>
                                                                        <td class="hra-bg-silver smaller-font">Co-insurance after deductible</td>
                                                                        <td class="hra-bg-bronze">100%</td>
                                                                        <td class="hra-bg-bronze smaller-font">Co-insurance after deductible</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="text-left">Diagnostic colonoscopies</td>
                                                                        <td class="hmo-bg-blue">100%</td>
                                                                        <td class="hra-bg-gold">100%</td>
                                                                        <td class="hra-bg-gold smaller-font">Co-insurance after deductible</td>
                                                                        <td class="hra-bg-silver">100%</td>
                                                                        <td class="hra-bg-silver smaller-font">Co-insurance after deductible</td>
                                                                        <td class="hra-bg-bronze">100%</td>
                                                                        <td class="hra-bg-bronze smaller-font">Co-insurance after deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Physician office services<sup>4</sup> (illness/injury)</td>
                                                                        <td class="hmo-bg-blue">100% after $35 co-pay</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Specialist office services (illness/injury)</td>
                                                                        <td class="hmo-bg-blue">100% after $45 co-pay</td>
                                                                        <td class="hra-bg-gold">100%</td>
                                                                        <td class="hra-bg-gold smaller-font">Co-insurance after deductible</td>
                                                                        <td class="hra-bg-silver">100%</td>
                                                                        <td class="hra-bg-silver smaller-font">Co-insurance after deductible</td>
                                                                        <td class="hra-bg-bronze">100%</td>
                                                                        <td class="hra-bg-bronze smaller-font">Co-insurance after deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">LiveHealth Online</td>
                                                                        <td class="hmo-bg-blue">100% after $35 co-pay</td>
                                                                        <td class="hra-bg-gold smaller-font">Co-insurance, no deductible</td>
                                                                        <td class="hra-bg-gold smaller-font">Not covered</td>
                                                                        <td class="hra-bg-silver smaller-font">Co-insurance, no deductible</td>
                                                                        <td class="hra-bg-silver smaller-font">Not covered</td>
                                                                        <td class="hra-bg-bronze smaller-font">Co-insurance, no deductible</td>
                                                                        <td class="hra-bg-bronze smaller-font">Not covered</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Chiropractic visit  (20 visits per plan year)</td>
                                                                        <td class="hmo-bg-blue">100% after $45 co-pay</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Eye exam—routine (limited to one exam every 24 months;  not subject to deductible) 100% coverage for dilated retinal  eye exams for diabetics once per calendar year</td>
                                                                        <td class="hmo-bg-blue">100%</td>
                                                                        <td class="hra-bg-gold">100%</td>
                                                                        <td class="hra-bg-gold">Not covered</td>
                                                                        <td class="hra-bg-silver">100%</td>
                                                                        <td class="hra-bg-silver">Not covered</td>
                                                                        <td class="hra-bg-bronze">100%</td>
                                                                        <td class="hra-bg-bronze">Not covered</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Hospital services (inpatient/outpatient)</td>
                                                                        <td class="hmo-bg-blue">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Maternity care <br/> <span>Physician routine prenatal care, delivery and postnatal</span></td>
                                                                        <td class="hmo-bg-blue">100% after $35 co-pay</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Outpatient rehabilitation therapy <br/><span>Physical, speech, cardiac, occupational, pulmonary  therapy (40 visits per therapy per plan year)</span></td>
                                                                        <td class="hmo-bg-blue">100% after $25 co-pay</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Emergency room care—hospital</td>
                                                                        <td class="hmo-bg-blue">100% after $200 co-pay</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Urgent care visit/retail health clinic</td>
                                                                        <td class="hmo-bg-blue">100% after $35 co-pay</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="first">&nbsp;</td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">HMO</h5></td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">Gold <br/>Base</h5></td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">Gold <br/>Total<sup class="fs14">*</sup></h5></td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">Silver <br/>Base</h5></td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">Silver <br/>Total<sup class="fs14">*</sup></h5></td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">Bronze <br/>Base</h5></td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">Bronze <br/>Total<sup class="fs14">*</sup></h5></td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="first">
                                                                              <h5>Well-Being Incentive Credits<sup>5,</sup> <sup>6</sup></h5>
                                                                        </td>
                                                                        <td class="hmo-bg-blue">480</td>
                                                                        <td class="hra-bg-gold">400</td>
                                                                        <td class="hra-bg-gold">880</td>
                                                                        <td class="hra-bg-silver">200</td>
                                                                        <td class="hra-bg-silver">680</td>
                                                                        <td class="hra-bg-bronze">100</td>
                                                                        <td class="hra-bg-bronze">580</td>
                                                                  </tr>
                                                            </tbody>
                                                      </table>
                                                </transition>

                                                <transition name="table-anim" enter-active-class="animate__animated animate__fadeIn animate__faster" leave-active-class="animate__animated animate__fadeOut animate__faster" mode="out-in">
                                                      <table v-if="benefitSelection == 'You-Spouse'">
                                                            <thead>
                                                                  <tr>
                                                                        <th width="30%">&nbsp;</th>
                                                                        <th width="10%" class="navy-blue-text">HMO<sup>1</sup></th>
                                                                        <th width="20%" colspan="2" class="navy-blue-text">HRA<br/>Gold<sup>1</sup></th>
                                                                        <th width="20%" colspan="2" class="navy-blue-text">HRA<br/>Silver<sup>1</sup></th>
                                                                        <th width="20%" colspan="2" class="navy-blue-text">HRA<br/>Bronze<sup>1</sup></th>
                                                                  </tr>
                                                            </thead>
                                                            <tbody>
                                                                  <tr>
                                                                        <td class="freeze-row" style="background-color:#ffffff;"><h5>Network</h5></td>
                                                                        <td class="hmo-bg-blue freeze-row"><strong>IN</strong></td>
                                                                        <td class="hra-bg-gold freeze-row"><strong>IN</strong></td>
                                                                        <td class="hra-bg-gold freeze-row"><strong>OUT</strong></td>
                                                                        <td class="hra-bg-silver freeze-row"><strong>IN</strong></td>
                                                                        <td class="hra-bg-silver freeze-row"><strong>OUT</strong></td>
                                                                        <td class="hra-bg-bronze freeze-row"><strong>IN</strong></td>
                                                                        <td class="hra-bg-bronze freeze-row"><strong>OUT</strong></td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="second">
                                                                              <h5>Deductible <span class="info-circle" @click="infoPop('Deductible', 'The amount you’re responsible for paying each plan year before your plan begins to cover its portion of the covered cost')"></span></h5>
                                                                        </td>
                                                                        <td class="hmo-bg-blue">$1,950</td>
                                                                        <td class="hra-bg-gold">$2,250</td>
                                                                        <td class="hra-bg-gold">$4,500</td>
                                                                        <td class="hra-bg-silver">$3,000</td>
                                                                        <td class="hra-bg-silver">$6,000</td>
                                                                        <td class="hra-bg-bronze">$3,750</td>
                                                                        <td class="hra-bg-bronze">$7,500</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="second">
                                                                              <h5>Co-insurance <span class="info-circle" @click="infoPop('Co-insurance', 'The percentage you pay for the cost of covered health expenses after meeting your deductible.')"></span></h5>
                                                                        </td>
                                                                        <td class="hmo-bg-blue">&nbsp;</td>
                                                                        <td class="hra-bg-gold">&nbsp;</td>
                                                                        <td class="hra-bg-gold">&nbsp;</td>
                                                                        <td class="hra-bg-silver">&nbsp;</td>
                                                                        <td class="hra-bg-silver">&nbsp;</td>
                                                                        <td class="hra-bg-bronze">&nbsp;</td>
                                                                        <td class="hra-bg-bronze">&nbsp;</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="text-left">Plan Pays</td>
                                                                        <td class="hmo-bg-blue">80% after deductible</td>
                                                                        <td class="hra-bg-gold">85% after deductible</td>
                                                                        <td class="hra-bg-gold">60% after deductible</td>
                                                                        <td class="hra-bg-silver">80% after deductible</td>
                                                                        <td class="hra-bg-silver">60% after deductible</td>
                                                                        <td class="hra-bg-bronze">75% after deductible</td>
                                                                        <td class="hra-bg-bronze">60% after deductible</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="text-left">Member Pays</td>
                                                                        <td class="hmo-bg-blue">20% after deductible</td>
                                                                        <td class="hra-bg-gold">15% after deductible</td>
                                                                        <td class="hra-bg-gold">40% after deductible</td>
                                                                        <td class="hra-bg-silver">20% after deductible</td>
                                                                        <td class="hra-bg-silver">40% after deductible</td>
                                                                        <td class="hra-bg-bronze">25% after deductible</td>
                                                                        <td class="hra-bg-bronze">40% after deductible</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="second">
                                                                              <h5>Out-of-pocket maximum<sup>2</sup> <span class="info-circle" @click="infoPop('Out-of-pocket maximum', 'The most you’ll have to pay in a plan year for covered medical expenses, including deductibles, co-pays (HMO plan only), co-insurance, and pharmacy costs. If you reach the out-of-pocket maximum, your eligible expenses are covered 100% by the plan for the remainder of the plan year.')"></span></h5>
                                                                        </td>
                                                                        <td class="hmo-bg-blue">$6,500</td>
                                                                        <td class="hra-bg-gold">$6,000</td>
                                                                        <td class="hra-bg-gold">$12,000</td>
                                                                        <td class="hra-bg-silver">$7,500</td>
                                                                        <td class="hra-bg-silver">$15,000</td>
                                                                        <td class="hra-bg-bronze">$9,000</td>
                                                                        <td class="hra-bg-bronze">$18,000</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="second">
                                                                              <h5>Medical</h5>
                                                                        </td>
                                                                        <td class="hmo-bg-blue">&nbsp;</td>
                                                                        <td class="hra-bg-gold">&nbsp;</td>
                                                                        <td class="hra-bg-gold">&nbsp;</td>
                                                                        <td class="hra-bg-silver">&nbsp;</td>
                                                                        <td class="hra-bg-silver">&nbsp;</td>
                                                                        <td class="hra-bg-bronze">&nbsp;</td>
                                                                        <td class="hra-bg-bronze">&nbsp;</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="text-left">Preventive care<sup>3</sup></td>
                                                                        <td class="hmo-bg-blue">100%</td>
                                                                        <td class="hra-bg-gold">100%</td>
                                                                        <td class="hra-bg-gold">Not covered</td>
                                                                        <td class="hra-bg-silver">100%</td>
                                                                        <td class="hra-bg-silver">Not covered</td>
                                                                        <td class="hra-bg-bronze">100%</td>
                                                                        <td class="hra-bg-bronze">Not covered</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="text-left">Diagnostic breast services (mammogram, ultrasound and MRI)</td>
                                                                        <td class="hmo-bg-blue">100%</td>
                                                                        <td class="hra-bg-gold">100%</td>
                                                                        <td class="hra-bg-gold smaller-font">Co-insurance after deductible</td>
                                                                        <td class="hra-bg-silver">100%</td>
                                                                        <td class="hra-bg-silver smaller-font">Co-insurance after deductible</td>
                                                                        <td class="hra-bg-bronze">100%</td>
                                                                        <td class="hra-bg-bronze smaller-font">Co-insurance after deductible</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="text-left">Diagnostic colonoscopies</td>
                                                                        <td class="hmo-bg-blue">100%</td>
                                                                        <td class="hra-bg-gold">100%</td>
                                                                        <td class="hra-bg-gold smaller-font">Co-insurance after deductible</td>
                                                                        <td class="hra-bg-silver">100%</td>
                                                                        <td class="hra-bg-silver smaller-font">Co-insurance after deductible</td>
                                                                        <td class="hra-bg-bronze">100%</td>
                                                                        <td class="hra-bg-bronze smaller-font">Co-insurance after deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Physician office services<sup>4</sup> (illness/injury)</td>
                                                                        <td class="hmo-bg-blue">100% after $35 co-pay</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Specialist office services (illness/injury)</td>
                                                                        <td class="hmo-bg-blue">100% after $45 co-pay</td>
                                                                        <td class="hra-bg-gold">100%</td>
                                                                        <td class="hra-bg-gold smaller-font">Co-insurance after deductible</td>
                                                                        <td class="hra-bg-silver">100%</td>
                                                                        <td class="hra-bg-silver smaller-font">Co-insurance after deductible</td>
                                                                        <td class="hra-bg-bronze">100%</td>
                                                                        <td class="hra-bg-bronze smaller-font">Co-insurance after deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">LiveHealth Online</td>
                                                                        <td class="hmo-bg-blue">100% after $35 co-pay</td>
                                                                        <td class="hra-bg-gold smaller-font">Co-insurance, no deductible</td>
                                                                        <td class="hra-bg-gold smaller-font">Not covered</td>
                                                                        <td class="hra-bg-silver smaller-font">Co-insurance, no deductible</td>
                                                                        <td class="hra-bg-silver smaller-font">Not covered</td>
                                                                        <td class="hra-bg-bronze smaller-font">Co-insurance, no deductible</td>
                                                                        <td class="hra-bg-bronze smaller-font">Not covered</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Chiropractic visit  (20 visits per plan year)</td>
                                                                        <td class="hmo-bg-blue">100% after $45 co-pay</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Eye exam—routine (limited to one exam every 24 months;  not subject to deductible) 100% coverage for dilated retinal  eye exams for diabetics once per calendar year</td>
                                                                        <td class="hmo-bg-blue">100%</td>
                                                                        <td class="hra-bg-gold">100%</td>
                                                                        <td class="hra-bg-gold">Not covered</td>
                                                                        <td class="hra-bg-silver">100%</td>
                                                                        <td class="hra-bg-silver">Not covered</td>
                                                                        <td class="hra-bg-bronze">100%</td>
                                                                        <td class="hra-bg-bronze">Not covered</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Hospital services (inpatient/outpatient)</td>
                                                                        <td class="hmo-bg-blue">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Maternity care<br/> <span>Physician routine prenatal care, delivery and postnatal</span></td>
                                                                        <td class="hmo-bg-blue">100% after $35 co-pay</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Outpatient rehabilitation therapy <br/><span>Physical, speech, cardiac, occupational, pulmonary  therapy (40 visits per therapy per plan year)</span></td>
                                                                        <td class="hmo-bg-blue">100% after $25 co-pay</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Emergency room care—hospital</td>
                                                                        <td class="hmo-bg-blue">100% after $200 co-pay</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Urgent care visit/retail health clinic</td>
                                                                        <td class="hmo-bg-blue">100% after $35 co-pay</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="first">&nbsp;</td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">HMO</h5></td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">Gold <br/>Base</h5></td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">Gold <br/>Total<sup class="fs14">*</sup></h5></td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">Silver <br/>Base</h5></td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">Silver <br/>Total<sup class="fs14">*</sup></h5></td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">Bronze <br/>Base</h5></td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">Bronze <br/>Total<sup class="fs14">*</sup></h5></td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="first">
                                                                              <h5>Well-Being Incentive Credits<sup>5,</sup> <sup>6</sup></h5>
                                                                        </td>
                                                                        <td class="hmo-bg-blue">&nbsp;</td>
                                                                        <td class="hra-bg-gold">&nbsp;</td>
                                                                        <td class="hra-bg-gold">&nbsp;</td>
                                                                        <td class="hra-bg-silver">&nbsp;</td>
                                                                        <td class="hra-bg-silver">&nbsp;</td>
                                                                        <td class="hra-bg-bronze">&nbsp;</td>
                                                                        <td class="hra-bg-bronze">&nbsp;</td>
                                                                  </tr>
                                                                   <tr class="row-border-top">
                                                                        <td class="text-left">You + Spouse</td>
                                                                        <td class="hmo-bg-blue">960</td>
                                                                        <td class="hra-bg-gold">600</td>
                                                                        <td class="hra-bg-gold">1,560</td>
                                                                        <td class="hra-bg-silver">300</td>
                                                                        <td class="hra-bg-silver">1,260</td>
                                                                        <td class="hra-bg-bronze">150</td>
                                                                        <td class="hra-bg-bronze">1,110</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="text-left">You + Child(ren)</td>
                                                                        <td class="hmo-bg-blue">480</td>
                                                                        <td class="hra-bg-gold">600</td>
                                                                        <td class="hra-bg-gold">1,080</td>
                                                                        <td class="hra-bg-silver">300</td>
                                                                        <td class="hra-bg-silver">780</td>
                                                                        <td class="hra-bg-bronze">150</td>
                                                                        <td class="hra-bg-bronze">630</td>
                                                                  </tr>
                                                            </tbody>
                                                      </table>
                                                </transition>

                                                <transition name="table-anim" enter-active-class="animate__animated animate__fadeIn animate__faster" leave-active-class="animate__animated animate__fadeOut animate__faster" mode="out-in">
                                                      <table v-if="benefitSelection == 'You-Family'">
                                                            <thead>
                                                                  <tr>
                                                                        <th width="30%">&nbsp;</th>
                                                                        <th width="10%" class="navy-blue-text">HMO<sup>1</sup></th>
                                                                        <th width="20%" colspan="2" class="navy-blue-text">HRA<br/>Gold<sup>1</sup></th>
                                                                        <th width="20%" colspan="2" class="navy-blue-text">HRA<br/>Silver<sup>1</sup></th>
                                                                        <th width="20%" colspan="2" class="navy-blue-text">HRA<br/>Bronze<sup>1</sup></th>
                                                                  </tr>
                                                            </thead>
                                                            <tbody>
                                                                  <tr>
                                                                        <td class="freeze-row" style="background-color:#ffffff;"><h5>Network</h5></td>
                                                                        <td class="hmo-bg-blue freeze-row"><strong>IN</strong></td>
                                                                        <td class="hra-bg-gold freeze-row"><strong>IN</strong></td>
                                                                        <td class="hra-bg-gold freeze-row"><strong>OUT</strong></td>
                                                                        <td class="hra-bg-silver freeze-row"><strong>IN</strong></td>
                                                                        <td class="hra-bg-silver freeze-row"><strong>OUT</strong></td>
                                                                        <td class="hra-bg-bronze freeze-row"><strong>IN</strong></td>
                                                                        <td class="hra-bg-bronze freeze-row"><strong>OUT</strong></td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="first">
                                                                              <h5>Deductible <span class="info-circle" @click="infoPop('Deductible', 'The amount you’re responsible for paying each plan year before your plan begins to cover its portion of the covered cost')"></span></h5>
                                                                        </td>
                                                                        <td class="hmo-bg-blue">$2,600</td>
                                                                        <td class="hra-bg-gold">$3,000</td>
                                                                        <td class="hra-bg-gold">$6,000</td>
                                                                        <td class="hra-bg-silver">$4,000</td>
                                                                        <td class="hra-bg-silver">$8,000</td>
                                                                        <td class="hra-bg-bronze">$5,000</td>
                                                                        <td class="hra-bg-bronze">$10,000</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="second">
                                                                              <h5>Co-insurance <span class="info-circle" @click="infoPop('Co-insurance', 'The percentage you pay for the cost of covered health expenses after meeting your deductible.')"></span></h5>
                                                                        </td>
                                                                        <td class="hmo-bg-blue">&nbsp;</td>
                                                                        <td class="hra-bg-gold">&nbsp;</td>
                                                                        <td class="hra-bg-gold">&nbsp;</td>
                                                                        <td class="hra-bg-silver">&nbsp;</td>
                                                                        <td class="hra-bg-silver">&nbsp;</td>
                                                                        <td class="hra-bg-bronze">&nbsp;</td>
                                                                        <td class="hra-bg-bronze">&nbsp;</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="text-left">Plan Pays</td>
                                                                        <td class="hmo-bg-blue">80% after deductible</td>
                                                                        <td class="hra-bg-gold">85% after deductible</td>
                                                                        <td class="hra-bg-gold">60% after deductible</td>
                                                                        <td class="hra-bg-silver">80% after deductible</td>
                                                                        <td class="hra-bg-silver">60% after deductible</td>
                                                                        <td class="hra-bg-bronze">75% after deductible</td>
                                                                        <td class="hra-bg-bronze">60% after deductible</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="text-left">Member Pays</td>
                                                                        <td class="hmo-bg-blue">20% after deductible</td>
                                                                        <td class="hra-bg-gold">15% after deductible</td>
                                                                        <td class="hra-bg-gold">40% after deductible</td>
                                                                        <td class="hra-bg-silver">20% after deductible</td>
                                                                        <td class="hra-bg-silver">40% after deductible</td>
                                                                        <td class="hra-bg-bronze">25% after deductible</td>
                                                                        <td class="hra-bg-bronze">40% after deductible</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="first">
                                                                              <h5>Out-of-pocket maximum<sup>2</sup> <span class="info-circle" @click="infoPop('Out-of-pocket maximum', 'The most you’ll have to pay in a plan year for covered medical expenses, including deductibles, co-pays (HMO plan only), co-insurance, and pharmacy costs. If you reach the out-of-pocket maximum, your eligible expenses are covered 100% by the plan for the remainder of the plan year.')"></span></h5>
                                                                        </td>
                                                                        <td class="hmo-bg-blue">$9,000</td>
                                                                        <td class="hra-bg-gold">$8,000</td>
                                                                        <td class="hra-bg-gold">$16,000</td>
                                                                        <td class="hra-bg-silver">$10,000</td>
                                                                        <td class="hra-bg-silver">$20,000</td>
                                                                        <td class="hra-bg-bronze">$12,000</td>
                                                                        <td class="hra-bg-bronze">$24,000</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="second">
                                                                              <h5>Medical</h5>
                                                                        </td>
                                                                        <td class="hmo-bg-blue">&nbsp;</td>
                                                                        <td class="hra-bg-gold">&nbsp;</td>
                                                                        <td class="hra-bg-gold">&nbsp;</td>
                                                                        <td class="hra-bg-silver">&nbsp;</td>
                                                                        <td class="hra-bg-silver">&nbsp;</td>
                                                                        <td class="hra-bg-bronze">&nbsp;</td>
                                                                        <td class="hra-bg-bronze">&nbsp;</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="text-left">Preventive care<sup>3</sup></td>
                                                                        <td class="hmo-bg-blue">100%</td>
                                                                        <td class="hra-bg-gold">100%</td>
                                                                        <td class="hra-bg-gold">Not covered</td>
                                                                        <td class="hra-bg-silver">100%</td>
                                                                        <td class="hra-bg-silver">Not covered</td>
                                                                        <td class="hra-bg-bronze">100%</td>
                                                                        <td class="hra-bg-bronze">Not covered</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="text-left">Diagnostic breast services (mammogram, ultrasound and MRI)</td>
                                                                        <td class="hmo-bg-blue">100%</td>
                                                                        <td class="hra-bg-gold">100%</td>
                                                                        <td class="hra-bg-gold smaller-font">Co-insurance after deductible</td>
                                                                        <td class="hra-bg-silver">100%</td>
                                                                        <td class="hra-bg-silver smaller-font">Co-insurance after deductible</td>
                                                                        <td class="hra-bg-bronze">100%</td>
                                                                        <td class="hra-bg-bronze smaller-font">Co-insurance after deductible</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="text-left">Diagnostic colonoscopies</td>
                                                                        <td class="hmo-bg-blue">100%</td>
                                                                        <td class="hra-bg-gold">100%</td>
                                                                        <td class="hra-bg-gold smaller-font">Co-insurance after deductible</td>
                                                                        <td class="hra-bg-silver">100%</td>
                                                                        <td class="hra-bg-silver smaller-font">Co-insurance after deductible</td>
                                                                        <td class="hra-bg-bronze">100%</td>
                                                                        <td class="hra-bg-bronze smaller-font">Co-insurance after deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Physician office services<sup>4</sup> (illness/injury)</td>
                                                                        <td class="hmo-bg-blue">100% after $35 co-pay</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Specialist office services (illness/injury)</td>
                                                                        <td class="hmo-bg-blue">100% after $45 co-pay</td>
                                                                        <td class="hra-bg-gold">100%</td>
                                                                        <td class="hra-bg-gold smaller-font">Co-insurance after deductible</td>
                                                                        <td class="hra-bg-silver">100%</td>
                                                                        <td class="hra-bg-silver smaller-font">Co-insurance after deductible</td>
                                                                        <td class="hra-bg-bronze">100%</td>
                                                                        <td class="hra-bg-bronze smaller-font">Co-insurance after deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">LiveHealth Online</td>
                                                                        <td class="hmo-bg-blue">100% after $35 co-pay</td>
                                                                        <td class="hra-bg-gold smaller-font">Co-insurance, no deductible</td>
                                                                        <td class="hra-bg-gold smaller-font">Not covered</td>
                                                                        <td class="hra-bg-silver smaller-font">Co-insurance, no deductible</td>
                                                                        <td class="hra-bg-silver smaller-font">Not covered</td>
                                                                        <td class="hra-bg-bronze smaller-font">Co-insurance, no deductible</td>
                                                                        <td class="hra-bg-bronze smaller-font">Not covered</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Chiropractic visit  (20 visits per plan year)</td>
                                                                        <td class="hmo-bg-blue">100% after $45 co-pay</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Eye exam—routine (limited to one exam every 24 months;  not subject to deductible) 100% coverage for dilated retinal  eye exams for diabetics once per calendar year</td>
                                                                        <td class="hmo-bg-blue">100%</td>
                                                                        <td class="hra-bg-gold">100%</td>
                                                                        <td class="hra-bg-gold">Not covered</td>
                                                                        <td class="hra-bg-silver">100%</td>
                                                                        <td class="hra-bg-silver">Not covered</td>
                                                                        <td class="hra-bg-bronze">100%</td>
                                                                        <td class="hra-bg-bronze">Not covered</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Hospital services (inpatient/outpatient)</td>
                                                                        <td class="hmo-bg-blue">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Maternity care<br/> <span>Physician routine prenatal care, delivery and postnatal</span></td>
                                                                        <td class="hmo-bg-blue">100% after $35 co-pay</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Outpatient rehabilitation therapy<br/> <span>Physical, speech, cardiac, occupational, pulmonary  therapy (40 visits per therapy per plan year)</span></td>
                                                                        <td class="hmo-bg-blue">100% after $25 co-pay</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Emergency room care—hospital</td>
                                                                        <td class="hmo-bg-blue">100% after $200 co-pay</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td class="text-left">Urgent care visit/retail health clinic</td>
                                                                        <td class="hmo-bg-blue">100% after $35 co-pay</td>
                                                                        <td colspan="2" class="hra-bg-gold">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-silver">Co-insurance after<br/> deductible</td>
                                                                        <td colspan="2" class="hra-bg-bronze">Co-insurance after<br/> deductible</td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="first">&nbsp;</td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">HMO</h5></td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">Gold <br/>Base</h5></td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">Gold <br/>Total<sup class="fs14">*</sup></h5></td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">Silver <br/>Base</h5></td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">Silver <br/>Total<sup class="fs14">*</sup></h5></td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">Bronze <br/>Base</h5></td>
                                                                        <td class="pt40"><h5 class="blue-text text-cent fw700">Bronze <br/>Total<sup class="fs14">*</sup></h5></td>
                                                                  </tr>
                                                                  <tr class="row-border-top">
                                                                        <td class="first">
                                                                              <h5>Well-Being Incentive Credits<sup>5,</sup> <sup>6</sup></h5>
                                                                        </td>
                                                                        <td class="hmo-bg-blue">960</td>
                                                                        <td class="hra-bg-gold">800</td>
                                                                        <td class="hra-bg-gold">1,760</td>
                                                                        <td class="hra-bg-silver">400</td>
                                                                        <td class="hra-bg-silver">1,360</td>
                                                                        <td class="hra-bg-bronze">200</td>
                                                                        <td class="hra-bg-bronze">1,160</td>
                                                                  </tr>
                                                            </tbody>
                                                      </table>
                                                </transition>
                                          </div>
                                    </div>
                                    <div class="grid-x grid-padding-x mb24">
                                          <div class="cell medium-12">
                                                <ol>
                                                      <p class="fs12" style="margin-left: 1.25rem;margin-bottom:4px !important;"><sup class="fs14">*</sup> Totals equals the base and completion of 2024 well-being incentive actions.</p>
                                                      <li>Out-of-network coverage is not available for the HMO plan except for emergency care. If you use an out-of-network provider on the HRA plans you could be balance billed.</li>
                                                      <li>Pharmacy costs count toward your out-of-pocket maximum.</li>
                                                      <li>Services must be properly coded as preventive care under the Patient Protection and Affordable Care Act and provided by an in-network doctor.</li>
                                                      <li>Physician office services is defined as family practice, general practice, internal medicine, pediatrics, and OB/GYN.</li>
                                                      <li>Completing your well-being incentive actions with Sharecare will earn you well-being incentive points. You can choose to redeem these points as well-being incentive credits (indicated in this chart) to use on covered medical and pharmacy expenses or the Visa Prepaid Card option. Visit BeWellSHBP.com for more information.</li>
                                                      <li>If you are on the HMO plan and redeem your well-being incentive points as credits, they will go into your MyIncentive Account, which is a standalone account offered alongside the HMO plan. This is a high-level summary of benefits. It does not describe all benefits and does not describe exclusions and limitations. The plan documents posted on www.shbp.georgia.gov include the full details. Dollar amounts, visit limitations, medical co-pays (HMO), co-insurance, deductible, and out-of-pocket limits are based on January 1 – December 31, 2023, plan year. All covered medical services are subject to deductible except preventive care, LiveHealth Online visits, ABA therapy, and hearing aids. Note: Medical co-pays (HMO) do not count toward the deductible but do count toward the out-of-pocket maximum.</li>
                                                </ol>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
            <NextButton link="/one-more-question-current"/>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import NextButton from "../components/NextButton"
      import gaEventMixin from "../mixins/gaEventMixin"
      export default {
            name: "SHBPBenefitsCurrent",
            data (){
                  return{
                        benefitSelection: "",
                        benefitOptions: [
                              { text: 'You', value: 'You' },
                              { text: 'You + Spouse or Child(ren)', value: 'You-Spouse' },
                              { text: 'You + Family', value: 'You-Family' }
                        ]
                  }
            },
            methods:{
                  infoPop(title, text){
                        let message = "<h6 class='text-left dark-blue-text fs18 fw600 mb8'>" + title + "</h6><p class='text-left mb0'>" + text + "</p>"
                        this.$swal({
                              html: message,
                              showCancelButton: false
                        })
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
                  if(!this.$store.state.coverage || this.$store.state.coverage=="") this.benefitSelection = "You"
                  else if(this.$store.state.coverage == "You-Child(ren)") this.benefitSelection = "You-Spouse"
                  else this.benefitSelection = this.$store.state.coverage
            },
            components: {
                  BackButton,
                  NextButton
            },
            mixins: [gaEventMixin]
      }
</script>

<style lang="scss">
      #benefits-current{
            .back-btn{
                  left: 0.65rem;
            }
            .bottom-content{
                 margin-top: 68px;
                 padding: 100px 0px 80px 0px;
                 @media only screen and (max-width:640px){
                        padding: 40px 20px 80px 20px;
                  }
                 h1{
                        color: #002E58;
                       margin-top: 3rem;
                       margin-bottom: 4rem;
                       @media only screen and (max-width:640px){
                              margin-top: 4rem;
                              margin-bottom: 2rem;
                        }
                 }
           }
           .swal2-popup{
                 border: solid 2px #0079C2;
           }
            .mb0{
                  margin-bottom: 0px !important;
            }
            sup.fs14{
                  top: -4px !important;
            }
            .text-right{
                  text-align: right !important;
            }
            .text-left{
                  span{
                        font-size: 0.75rem;
                  }
            }
            .baby-blue{
                  font-weight: 900;
            }
            .hmo-bg-blue{
                  background-color: #E1EDFF;
                  border-bottom: none;
            }
            .hra-bg-gold{
                  background-color: rgba(225, 237, 255, 0.5) !important;
                  border-bottom: none;
            }
            .hra-bg-silver{
                  background-color: #E1EDFF !important;
                  border-bottom: none;
            }
            .hra-bg-bronze{
                  background-color: rgba(225, 237, 255, 0.5) !important;
                  border-bottom: none;
            }
            button.button{
                  margin: 0px;
                  font-size: 1rem;
                  padding: 17px 20px;
                  font-weight: 600;
                  border-radius: 32px;
                  min-width: 175px;
                  background-color: #0E63AF !important;
                  &:hover, &.active{
                        background-color: #E1EDFF !important;
                        color:#0A357C;
                  }
                  &.check-btn{
                        width: 100%;
                        min-width: inherit;
                        @media only screen and (max-width:640px){
                              margin-bottom: 0px;
                        }
                  }
                  @media only screen and (max-width:640px){
                        margin-bottom: 16px;
                  }
            }
            select{
                  background-color: #F1F5F6;
                  border: solid 2px #0079C2;
                  border-radius: 20px;
                  transition: all 0.2s ease-in-out 0s;
                  color:  #000000;
                  font-weight: 400;
                  font-size: 1.125rem;
                  padding-left:1rem;
                  cursor: pointer;
                  &:focus{
                        outline: 0;
                        box-shadow: 0 0 5px rgba(0,121,194,0.2);
                        border: solid 2px rgba(0, 121, 194, 0.7);
                  }
            }
            table{
                  --animate-duration: 0.2s;
            }
            thead{
                  background: none;
                  th{
                        text-align: center;
                        color:  #0079C2;
                        background-color: #ffffff;
                        font-size: 0.875rem !important;
                        line-height: 1.2;
                        padding: 0.75rem;
                        border-left: solid 1px #ffffff;
                        font-weight: 700;
                        position: sticky;
                        top: 68px;
                        &.hr-bg{
                              background-color: #69B3E7;
                        }
                        &.th-bg-white{
                              background-color: #ffffff;
                              z-index: 1;
                        }
                        @media only screen and (max-width:1440px) and (min-width: 960px){
                              font-size: 2.5vw !important;
                        }
                        @media only screen and (max-width:640px){
                              position: inherit;
                        }
                  }
            }
            tbody, tfoot, thead{
                  border:none;
            }
            tbody tr:nth-child(odd){
                  background: none;
            }
            tbody tr:nth-child(even) {
                  background: none;
            }
            tbody{
                  tr{
                        &.row-border-top{
                              border-top: none;
                        }
                  }
                  td{
                        font-size: 0.875rem;
                        padding: 0.75rem 0.5rem;
                        text-align: center;
                        &.first{
                              text-align: left;
                              background-color: #ffffff;
                              width:15%;
                        }
                        &.second{
                              text-align: left;
                              background-color: #ffffff;
                              width:35%;
                        }
                        &.freeze-row{
                              position: sticky;
                              top: 125px;
                              z-index: 1;
                              @media only screen and (max-width:640px){
                                    position: inherit;
                              }
                        }
                        p{
                              &.color-blue{
                                    margin-bottom: 0 !important;
                                    margin-top: 24px;
                                    color: #012169;
                                    font-weight: 600;
                              }
                        }
                        @media only screen and (max-width:1440px) and (min-width: 960px){
                              font-size: 1.111vw !important;
                        }
                        &.smaller-font{
                              font-size: 0.75rem;
                        }
                  }
                  h5{
                        text-align: left;
                        font-weight: 600;
                        font-size: 0.875rem;
                        color: #002E58;
                        margin-bottom: 4px;
                        i{
                              cursor: pointer;
                              color: #0079C2;
                              font-size: 0.75rem;
                              transition: all 0.2s ease-in-out 0s;
                              &:hover{
                                    color: #69B3E7;
                              }
                        }
                        span{
                              position: relative;
                              cursor: pointer;
                              &.info-circle{
                                    &:before{
                                          content:"";
                                          background-image: url("~@/assets/img/info-icon.png");
                                          position: absolute;
                                          top:50%;
                                          width: 12px;
                                          height: 12px;
                                          transform: translateY(-50%);
                                          background-repeat: no-repeat;
                                          background-size: 12px 12px;
                                          right: -16px;
                                    }
                              }
                        }
                        
                        @media only screen and (max-width:1440px) and (min-width: 960px){
                              font-size: 1.389vw !important;
                        }
                  }
                  p{
                        font-size: 1rem;
                        line-height: 1.25;
                        @media only screen and (max-width:1440px) and (min-width: 960px){
                              font-size: 1.111vw !important;
                              line-height: 1.5vw !important;
                        }
                  }
            }
            ol{
                  margin-left: 0;
                  li{
                        margin-left: 1.25rem;
                  }
            }
      }
</style>

<template>
      <section id="next-btn-bottom">
           <div class="grid-container">
                  <div class="grid-x grid-padding-x">
                        <div class="cell medium-12">
                              <router-link :to="link" class="next-btn button large"><span>{{ text }}</span></router-link>
                        </div>
                  </div>
            </div>
      </section>
</template>

<script>
      export default {
            name: "NextButton",
            props: {
                  link: {
                        type: String,
                        required: true,
                  },
                  text: {
                        type: String,
                        required: false,
                        default: "Next"
                  },
                  /*event: {
                        type: String,
                        required: false,
                        default: "Next"
                  }*/
            },
            methods:{
                  /*track(label) {
                        this.$gtag.event('Click', {
                              'event_category': 'CTA',
                              'event_label': label,
                              'value': 1
                        })
                  }*/
                  /*bookMark(){
                        const routes = this.$store.state.routeName
                        localStorage.setItem("antRouteNames", JSON.stringify(routes))
                  }*/
            }
      }
</script>

<style lang="scss">
      #next-btn-bottom, #check-btn-bottom{
            box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.10);
            padding: 20px 0px;
            background-color: #ffffff;
            position: fixed;
            bottom: 0;
            width: 100%;
            .next-btn{
                  background-color: #1A3673;
                  max-width: 330px;
                  border-radius: 30px;
                  display: block;
                  font-size: 1rem;
                  font-weight: 700;
                  margin: 0 auto;
                  color: #ffffff !important;
                  &:hover{
                        background-color: #1369b5;
                  }
            }
      }
</style>

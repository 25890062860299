<template>
    <div id="home">
        <section class="section hero">
            <div class="grid-container">
                <div id="hero-content" class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                    <div class="cell medium-6">
                        <h1>Connecting<br> comes with<br> benefits.</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="section bottom-content">
            <div class="grid-container">
                <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                    <div class="cell small-12 medium-6 large-6 first-column">
                        <p class="home-content">Welcome to the 2025<br class="hide-for-small" /> Anthem Blue Cross and
                            Blue Shield interactive health plan guide</p>
                    </div>
                    <div class="cell small-12 medium-6 large-5 large-offset-1 second-column">
                        <p>This interactive guide is designed to take you step-by-step to find the plan that best meets your needs and health goals. </p>
                    </div>
                </div>
            </div>
        </section>
        <NextButton link="/intro" text="Start" />
    </div>
</template>

<script>
import NextButton from "../components/NextButton"
export default {
    name: "SHBPHome",
    data() {
        return {
            pop: "Variable Field"
        }
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    components: {
        NextButton
    }
}

</script>

<style lang="scss">
#home {
    .hero {
        background-image: url("~@/assets~@/assets/img/home-hero-2024-lg.jpg");
        background-position: right top;
        background-repeat: no-repeat;
        background-size: auto;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.2);
        }
    }

    .bottom-content {
        .home-content {
            font-size: 1.5rem !important;
            line-height: 1.125 !important;
            color: #002E58 !important;
            font-weight: 700;

            @media only screen and (max-width: 640px) {
                font-size: 1.5rem !important;
            }
        }
    }
}
</style>
<template>
      <div id="welcome-back-current">
            <section class="section hero">
                  <div class="grid-container">
                        <BackButton link="/member" color="white"/>
                        <div id="hero-content" class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell medium-6">
                                    <h1>Welcome back, {{ firstName }}</h1>
                              </div>
                        </div>
                  </div>
            </section>
            <section class="section bottom-content">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn">
                              <div class="cell medium-5">
                                    <h3 class="navy-blue-text fw400">Are you:</h3>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn mb8">
                              <div class="cell medium-5">
                                    <div class="grid-x grid-padding-x">
                                          <label class="radio cell auto">
                                                <div class="grid-x grid-padding-x">
                                                      <div class="cell shrink">
                                                            <input type="radio" value="What plans have changed" v-model="changedOptions"/>
                                                      </div>
                                                      <div class="cell auto">
                                                            <span>generally familiar with our plans but would like a quick review?</span>
                                                      </div>
                                                </div>
                                          </label>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn">
                              <div class="cell medium-5">
                                    <div class="grid-x grid-padding-x">
                                          <label class="radio cell auto">
                                                <div class="grid-x grid-padding-x">
                                                      <div class="cell shrink">
                                                            <input type="radio" value="Explore detailed plan options" v-model="changedOptions"/>
                                                      </div>
                                                      <div class="cell auto">
                                                            <span>interested in exploring  detailed Plan Options for 2025?</span>
                                                      </div>
                                                </div>
                                          </label>
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
            <section id="check-btn-bottom">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x">
                              <div class="cell medium-12">
                                    <button class="button check-btn large" @click="storeData()">Continue</button>
                              </div>
                        </div>
                  </div>
            </section>
            
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import gaEventMixin from "../mixins/gaEventMixin"
      export default {
            name: "SHBPWelcomeBackCurrent",
            data (){
                  return{
                        firstName: this.$store.state.firstName,
                        changedOptions: this.$store.state.changedOptions
                  }
            },
            methods:{
                  storeData(){
                        const changedOptions = this.changedOptions
                        if((!changedOptions) ){
                              let message = 'Please make a choice below.'
                              this.$swal({
                                    html: message,
                                    showCancelButton: false
                              })
                        }
                        else{
                              this.$store.commit("changeChangedOptions", changedOptions)
                              this.track("Choice", changedOptions + " Coverage")
                              if(changedOptions=="Explore detailed plan options") this.$router.push("/welcome-back")
                              else this.$router.push("/overview-current")
                              localStorage.setItem("antChangedOptions", changedOptions)
                        }
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {BackButton},
            mixins: [gaEventMixin]
      }
</script>

<style lang="scss">
      #welcome-back-current{
            .hero{
                  background-image: none;
                  h1, p{
                        color: #ffffff !important;
                  }
                  #hero-content{
                        h1{
                              margin-bottom: 5rem;
                              @media only screen and (max-width:640px){
                                    margin-bottom: 96px;
                              }
                        }
                  }
            }
            h3{
                  color: #0E63AF;
                  font-size: 1.125rem;
                  font-weight: 400;
                  margin-bottom: 0.5rem;
            }
      }
</style>

import {createRouter,createWebHistory} from "vue-router";
import ShbpHome from "../views/home.vue"
import ShbpIntro from "../views/intro.vue"
import ShbpName from "../views/name.vue"
import ShbpMember from "../views/member.vue"
import ShbpConsideringCoverage from "../views/considering-coverage.vue"
import ShbpConsideringCoverageNew from "../views/considering-coverage-new.vue"
import ShbpKeepingPlan from "../views/keeping-plan.vue"
import ShbpWelcomeBack from "../views/welcome-back.vue"
import ShbpWelcomeBackCurrent from "../views/welcome-back-current.vue"
import ShbpOverviewCurrent from "../views/overview-current.vue"
import ShbpWelcomeNew from "../views/welcome-new.vue"
import ShbpWelcomeToAnthem from "../views/welcome-to-anthem.vue"
import ShbpChecklistCurrent from "../views/checklist-current.vue"
import ShbpBenefitsCurrent from "../views/benefits-current.vue"
import ShbpOneMoreQuestionCurrent from "../views/one-more-question-current.vue"
import ShbpSummaryCurrent from "../views/summary-current.vue"
import ShbpSummaryOptionsCurrent from "../views/summary-options-current.vue"
import ShbpSummary from "../views/summary.vue"
import ShbpChoosePlan from "../views/choose-plan.vue"
import ShbpPlanOptions from "../views/plan-options.vue"
import ShbpPlanOptionsCurrent from "../views/plan-options-current.vue"
import ShbpCompare from "../views/compare.vue"
import ShbpRewards from "../views/rewards.vue"
import ShbpPlanCredits from "../views/plan-credits.vue"
import ShbpResources from "../views/resources.vue"
import ShbpSydneyHealth from "../views/sydney-health.vue"
import ShbpLiveHealth from "../views/livehealth-online.vue"
import ShbpTotalHealth from "../views/total-health.vue"
import ShbpDermatologist from "../views/dermatologist.vue"
import ShbpBenefits from "../views/benefits.vue"
import ShbpChecklist from "../views/checklist.vue"
import ShbpImportantContacts from "../views/important-contacts.vue"
import store from "../store"

const routeNameList = store.state.routeName

const routes = [{
    path: "/",
    name: "SHBP Home",
    component: ShbpHome,
    meta: {
      title: "Home | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
      if (routeNameList.includes(to.name)) next()
      else {
        store.commit('updateRouteName', to.name)
        store.commit('updateRoutePath', to.path)
        next()
      }
    }
  },
  {
    path: "/intro",
    name: "SHBP Intro",
    component: ShbpIntro,
    meta: {
      title: "Introduction & Review | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
      if (routeNameList.includes(to.name)) next()
      else {
        store.commit('updateRouteName', to.name)
        store.commit('updateRoutePath', to.path)
        next()
      }
    }
  },
  {
    path: "/name",
    name: "SHBP Name",
    component: ShbpName,
    meta: {
      title: "Welcome Name | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
      if (routeNameList.length < 1) {
        next({
          name: "SHBP Intro"
        })
      } else {
        if (routeNameList.includes(to.name)) next()
        else {
          store.commit('updateRouteName', to.name)
          store.commit('updateRoutePath', to.path)
          next()
        }
      }
    }
  },
  {
    path: "/member",
    name: "SHBP Member",
    component: ShbpMember,
    meta: {
      title: "Anthem Member | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
      if (routeNameList.length < 1) {
        next({
          name: "SHBP Intro"
        })
      } else {
        if (routeNameList.includes(to.name)) next()
        else {
          store.commit('updateRouteName', to.name)
          store.commit('updateRoutePath', to.path)
          next()
        }
      }
    }
  },
  {
    path: "/welcome-back-current",
    name: "SHBP Welcome Back Current",
    component: ShbpWelcomeBackCurrent,
    meta: {
      title: "Welcome Back Current | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
      if (routeNameList.length < 1) {
        next({
          name: "SHBP Intro"
        })
      } else {
        if (routeNameList.includes(to.name)) next()
        else {
          store.commit('updateRouteName', to.name)
          store.commit('updateRoutePath', to.path)
          next()
        }
      }
    }
  },
  {
    path: "/welcome-new",
    name: "SHBP Welcome New",
    component: ShbpWelcomeNew,
    meta: {
      title: "Welcome New | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
      if (routeNameList.length < 1) {
        next({
          name: "SHBP Member"
        })
      } else {
        if (routeNameList.includes(to.name)) next()
        else {
          store.commit('updateRouteName', to.name)
          store.commit('updateRoutePath', to.path)
          next()
        }
      }
    }
  },
  {
    path: "/considering-coverage",
    name: "SHBP Considering Coverage",
    component: ShbpConsideringCoverage,
    meta: {
          title: "Considering Coverage | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
  },
  {
      path: "/considering-coverage-new",
      name: "SHBP Considering Coverage New",
      component: ShbpConsideringCoverageNew,
      meta: {
            title: "Considering Coverage New | SHBP Anthem"
      },
      beforeEnter(to, from, next) {
            if(routeNameList.length < 1){
                  next({
                        name: "SHBP Intro"
                  })
            }
            else{
                  if(routeNameList.includes(to.name)) next()
                  else{
                        store.commit('updateRouteName', to.name) 
                        store.commit('updateRoutePath', to.path)
                        next()
                  }
            }
      }
  },
  {
    path: "/keeping-plan",
    name: "SHBP Keeping Plan",
    component: ShbpKeepingPlan,
    meta: {
          title: "Keeping Plan | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
},
{
    path: "/welcome-back",
    name: "SHBP Welcome Back",
    component: ShbpWelcomeBack,
    meta: {
          title: "Welcome Back | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Member"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
  },
  {
    path: "/overview-current",
    name: "SHBP Overview Current",
    component: ShbpOverviewCurrent,
    meta: {
          title: "Overview Current | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
  },
  {
    path: "/welcome-to-anthem",
    name: "SHBP Welcome To Anthem",
    component: ShbpWelcomeToAnthem,
    meta: {
          title: "Welcome To Anthem | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Member"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
},
{
    path: "/checklist-current",
    name: "SHBP Checklist Current",
    component: ShbpChecklistCurrent,
    meta: {
          title: "Checklist Current | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
},
{
    path: "/benefits-current",
    name: "SHBP Benefits Current",
    component: ShbpBenefitsCurrent,
    meta: {
          title: "Benefits Current | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
},
{
    path: "/one-more-question-current",
    name: "SHBP One More Question Current",
    component: ShbpOneMoreQuestionCurrent,
    meta: {
          title: "One More Question Current | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
},
 {
    path: "/choose-plan",
    name: "SHBP Choose Plan",
    component: ShbpChoosePlan,
    meta: {
          title: "Choose Plan | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
},
{
    path: "/plan-options-current",
    name: "SHBP Plan Options Current",
    component: ShbpPlanOptionsCurrent,
    meta: {
          title: "Plan Options Current | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
},
{
    path: "/plan-options",
    name: "SHBP Plan Options",
    component: ShbpPlanOptions,
    meta: {
          title: "Plan Options | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
},
{
    path: "/compare",
    name: "SHBP Compare",
    component: ShbpCompare,
    meta: {
          title: "Compare | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
},
{
    path: "/rewards",
    name: "SHBP Rewards",
    component: ShbpRewards,
    meta: {
          title: "Rewards | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
},
{
    path: "/plan-credits",
    name: "SHBP Plan Credits",
    component: ShbpPlanCredits,
    meta: {
          title: "Plan Credits | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
},
{
    path: "/resources",
    name: "SHBP Resources",
    component: ShbpResources,
    meta: {
          title: "Resources | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
},
 {
    path: "/sydney-health",
    name: "SHBP Sydney Health",
    component: ShbpSydneyHealth,
    meta: {
          title: "Sydney Health | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
},
{
    path: "/livehealth-online",
    name: "SHBP LiveHealth Online",
    component: ShbpLiveHealth,
    meta: {
          title: "LiveHealth Online | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
},
{
    path: "/total-health",
    name: "SHBP Total Health",
    component: ShbpTotalHealth,
    meta: {
          title: "Total Health | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
},
{
    path: "/dermatologist",
    name: "SHBP Dermatologist",
    component: ShbpDermatologist,
    meta: {
          title: "Dermatologist | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
},
{
    path: "/benefits",
    name: "SHBP Benefits",
    component: ShbpBenefits,
    meta: {
          title: "Benefits | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
},
{
    path: "/summary-current",
    name: "SHBP Summary Current",
    component: ShbpSummaryCurrent,
    meta: {
          title: "Summary Current | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
},
{
    path: "/summary-options-current",
    name: "SHBP Summary Options Current",
    component: ShbpSummaryOptionsCurrent,
    meta: {
          title: "Summary Options Current | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
},
{
    path: "/summary",
    name: "SHBP Summary",
    component: ShbpSummary,
    meta: {
          title: "Summary | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Summary"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
  },
  {
    path: "/checklist",
    name: "SHBP Checklist",
    component: ShbpChecklist,
    meta: {
          title: "Checklist | SHBP Anthem"
    },
    beforeEnter(to, from, next) {
          if(routeNameList.length < 1){
                next({
                      name: "SHBP Intro"
                })
          }
          else{
                if(routeNameList.includes(to.name)) next()
                else{
                      store.commit('updateRouteName', to.name) 
                      store.commit('updateRoutePath', to.path)
                      next()
                }
          }
    }
  },
  {
      path: "/important-contacts",
      name: "SHBP Important Contacts",
      component: ShbpImportantContacts,
      meta: {
            title: "Important Contacts | SHBP Anthem"
      }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.afterEach((to) => {
  //const routePaths = store.state.routePath,
    //    antRoutePaths = localStorage.getItem("antRoutePaths")
  if (to.meta && to.meta.title) {
        document.title = to.meta.title || "SHBP Anthem"
  }
  //if(!antRoutePaths || antRoutePaths=="") localStorage.setItem("antRoutePaths", JSON.stringify(routePaths))
  //else next()
})

export default router;
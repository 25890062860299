<template>
      <div id="checklist">
            <section class="section hero">
                  <div class="grid-container">
                        <BackButton link="/overview-current"/>
                        <div id="hero-content" class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn checklist-hero-content">
                              <div class="cell large-12">
                                    <h1>Your checklist for 2025</h1>
                              </div>
                        </div>
                  </div>
            </section>
            <section class="section bottom-content">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell large-5 first-column">
                                    <p class="fs18 navy-blue-text fw400">If you don't want to make any changes to your current Anthem Plan Option and tier, your plan will automatically roll over for 2025.</p>
                                    <p>This year, the Open Enrollment selection period is <br/><a href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20241015%2F20241108&details=This%20year%2C%20the%20Open%20Enrollment%20selection%20period%20is%20October%2015%2C%202024%20-%0ANovember%208%2C%202024.%20&text=Anthem%20Open%20Enrollment" class="navy-blue-text underline" target="_blank" @click="track('Link', 'Add To Calendar - Checklist Current')">October 15 - November 8, 2024</a></p>
                              </div>
                              <div class="cell large-4 medium-offset-3 second-column">
                                    <div class="checklist-box one">
                                          <h5>Check</h5>
                                          <p>your premium rates for Plan Options at <a href="https://shbp.georgia.gov" class="navy-blue-text underline" target="_blank" @click="track('Link', 'shbp.georgia.gov - Checklist Current')">shbp.georgia.gov</a> to see your monthly payment.</p>
                                    </div>
                                    <div class="checklist-box two">
                                          <h5>Review</h5>
                                          <p>the information here to compare Anthem's Plan Options. If you need assistance, use the enrollment resources available at: <a href="https://anthem.com/shbp" class="navy-blue-text underline" target="_blank" @click="track('Link', 'anthem.com/shbp - Checklist Current')">anthem.com/shbp</a>.</p>
                                    </div>
                                    <div class="checklist-box three">
                                          <h5>Log-in</h5>
                                          <p>at <a href="https://mySHBPga.adp.com" class="navy-blue-text underline" target="_blank" @click="track('Link', 'mySHBPga.adp.com - Checklist Current')">mySHBPga.adp.com</a> to select your Plan Option and tier during Open Enrollment if you want to make a change.</p>
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
            <NextButton link="/benefits-current"/>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import NextButton from "../components/NextButton"
      import gaEventMixin from "../mixins/gaEventMixin"
      export default {
            name: "SHBPChecklistCurrent",
            data (){
                  return{
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {
                  BackButton,
                  NextButton
            },
            mixins: [gaEventMixin]
      }
</script>

<style lang="scss">
      #checklist{
            .hero{
                  &:before{
                        display: none;
                  }
            }
            #hero-content{
                  h1{
                        color: #002E58;
                  }
            }
      }
</style>

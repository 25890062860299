<template>
      <div id="compare">
            <section class="section bottom-content">
                  <div class="grid-container">
                        <BackButton link="/plan-options"/>
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb32">
                              <div class="cell large-10">
                                    <h1>Compare your options</h1>
                              </div>
                              <div class="cell large-12">
                                    <div class="grid-x grid-padding-x">
                                          <div class="cell large-10">
                                                <p class="fs18 navy-blue-text fw400">Our HMO and HRA plans are designed to accommodate a range of financial and health goals. Here's a high-level comparison to help you select the one that best fits you and your family.</p>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x mb80">
                              <div class="cell medium-12">
                                    <table>
                                          <thead class="plan-title-head">
                                                <tr>
                                                      <th></th>
                                                      <th class="sticky">HMO</th>
                                                      <th class="sticky">HRA</th>
                                                </tr>
                                          </thead>
                                          <thead>
                                                <tr>
                                                      <th colspan="3" class="th-section new-blue-text">Paying for your care</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                <tr>
                                                      <td><strong>Co-pays</strong><br/> 
A flat negotiated fee you pay at the point of service for certain covered medical and pharmacy services</td>
                                                      <td class="baby-blue" width="100"><img src="img/check.svg"/></td>
                                                      <td class="baby-blue" width="100"></td>
                                                </tr>
                                                <tr>
                                                      <td><strong>Co-insurance</strong> <br/>
The percentage you pay for the cost of covered health expenses after meeting your deductible</td>
                                                      <td class="baby-blue"><img src="img/check.svg"/></td>
                                                      <td class="baby-blue"><img src="img/check.svg"/></td>
                                                </tr>
                                                <tr>
                                                      <td><strong>Deductible</strong> <br/>
The amount you’re responsible for paying each plan year before your plan begins to cover its portion of the covered cost</td>
                                                      <td class="baby-blue"><img src="img/check.svg"/></td>
                                                      <td class="baby-blue"><img src="img/check.svg"/></td>
                                                </tr>
                                                <tr>
                                                      <td><strong>Preventive care covered at 100%<sup>1</sup> </strong><br/>
When provided by an in-network doctor, encouraging you to be proactive in your health</td>
                                                      <td class="baby-blue"><img src="img/check.svg"/></td>
                                                      <td class="baby-blue"><img src="img/check.svg"/></td>
                                                </tr>
                                                <tr>
                                                      <td><strong>Medical and pharmacy<sup>2</sup> costs count toward your out-of-pocket maximum </strong><br/>
                                                            Expenses you pay for covered medical care — including prescriptions, co-pays (HMO only), co-insurance, and deductibles — apply to your out-of-pocket maximum</td>
                                                      <td class="baby-blue"><img src="img/check.svg"/></td>
                                                      <td class="baby-blue"><img src="img/check.svg"/></td>
                                                </tr>
                                          </tbody>
                                          <thead>
                                                <tr>
                                                      <th colspan="3" class="th-section new-blue-text">Access to<br class="hide-for-medium"/> providers </th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                <tr>
                                                      <td><strong>Access to Anthem's expansive network of providers</strong><br/> 
Includes coverage in Georgia, nationally and internationally</td>
                                                      <td class="baby-blue" width="100"><img src="img/check.svg"/></td>
                                                      <td class="baby-blue" width="100"><img src="img/check.svg"/></td>
                                                </tr>
                                                <tr>
                                                      <td><strong>See any provider you choose</strong> <br/>
Flexibility to see a provider in-network or out-of-network</td>
                                                      <td class="baby-blue"></td>
                                                      <td class="baby-blue"><img src="img/check.svg"/></td>
                                                </tr>
                                                <tr>
                                                      <td><strong>No referrals necessary</strong><br/>
Both plans are open-access, so no PCP referral is needed to see a specialist</td>
                                                      <td class="baby-blue"><img src="img/check.svg"/></td>
                                                      <td class="baby-blue"><img src="img/check.svg"/></td>
                                                </tr>
                                                <tr>
                                                      <td><strong>Access to LiveHealth Online </strong><br/>
See a provider online 24/7 </td>
                                                      <td class="baby-blue"><img src="img/check.svg"/></td>
                                                      <td class="baby-blue"><img src="img/check.svg"/></td>
                                                </tr>
                                          </tbody>
                                          <thead>
                                                <tr>
                                                      <th colspan="3" class="th-section new-blue-text">Incentive credits<br class="hide-for-medium"/> to help reduce<br class="hide-for-medium"/> your costs</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                <tr>
                                                      <td><strong>Plan comes with starter credits contributed by SHBP </strong><br/> 
From 100 to 800 depending on the Plan Option and tier you select</td>
                                                      <td class="baby-blue" width="100"></td>
                                                      <td class="baby-blue" width="100"><img src="img/check.svg"/></td>
                                                </tr>
                                                <tr>
                                                      <td><strong>Earn points </strong> <br/>
You can choose to redeem these points for well-being incentive credits to use on covered medical and pharmacy expenses<sup>3</sup></td>
                                                      <td class="baby-blue"><img src="img/check.svg"/></td>
                                                      <td class="baby-blue"><img src="img/check.svg"/></td>
                                                </tr>
                                                <tr>
                                                      <td><strong>Roll over unused credits from 2024</strong></td>
                                                      <td class="baby-blue"><img src="img/check.svg"/></td>
                                                      <td class="baby-blue"><img src="img/check.svg"/></td>
                                                </tr>
                                          </tbody>
                                    </table>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x mb40">
                              <div class="cell medium-12">
                                    <p class="fs12">1. Services must be properly coded as preventive care under the Patient Protection and Affordable Care Act. 2. Pharmacy is administered by SHBP's partner CVS Caremark. 3. Completing your well-being incentive actions with Sharecare will earn
you points. You can choose to redeem these points for well-being incentive credits to use on covered medical and pharmacy expenses or the Visa Prepaid Card option. Visit <a href="https://BeWellSHBP.com" class="navy-blue-text fw400" target="_blank">BeWellSHBP.com</a> for more information.</p>
                              </div>
                        </div>
                  </div>
            </section>
            <NextButton link="/resources"/>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import NextButton from "../components/NextButton"
      export default {
            name: "SHBPCompare",
            data (){
                  return{
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {
                  BackButton,
                  NextButton
            }
      }
</script>

<style lang="scss">
      #compare{
            .back-btn{
                  left: 0.65rem;
            }
           .bottom-content{
                 margin-top: 68px;
                 padding: 100px 0px 80px 0px;
                 @media only screen and (max-width:640px){
                        padding: 40px 20px 80px 20px;
                  }
                 h1{
                        color: #002E58;
                       margin-top: 3rem;
                       margin-bottom: 4rem;
                       @media only screen and (max-width:640px){
                              margin-top: 4rem;
                              margin-bottom: 2rem;
                        }
                 }
           }
           thead{
                  background-color: #E1EDFF;
                  color:#ffffff;
                  font-size: 1rem;
                  th{
                        &.th-section{
                              padding: 1.25rem 1rem;
                        }
                        span{
                              display: block;
                              font-size: 0.7rem;
                              color: rgba(50, 56, 61, 0.8);
                              font-weight: 400;
                              margin-top: 2px;
                        }
                  }
            }
            thead.plan-title-head{
                  background-color: #ffffff;
                  th{
                        text-align: center;
                        color:#1A3673;
                        font-size: 1.125rem;
                        font-weight: 600;
                        line-height: 1;
                        position:sticky;
                        top:68px;
                        background-color: #ffffff;
                        z-index: 1;
                        padding: 1rem 0.5rem;
                  }
            }
            tbody, tfoot, thead{
                  border:none;
            }
            tbody tr:nth-child(even) {
                  background-color: rgba(50,56,61, 0.04);
            }
            td{
                  font-size: 0.875rem;
                  font-weight: 400;
                  padding: 1.5rem 1rem;
                  strong{
                        color: #1355E9;
                        font-weight: 400;
                  }
                  img{
                        filter: brightness(0) saturate(100%) invert(14%) sepia(34%) saturate(4633%) hue-rotate(212deg) brightness(90%) contrast(89%);
                        width: 40px;
                  }
            }
            .baby-blue{
                  font-weight: 700;
                  font-size: 2.25rem;
                  text-align: center;
            }
      }
</style>

<template>
      <div id="welcome-to-anthem">
            <section class="section hero">
                  <div class="grid-container">
                        <div v-if="currMember == 'New Prospective'">
                              <BackButton link="/considering-coverage-new" color="white"/>
                        </div>
                        <div v-else>
                              <BackButton link="/keeping-plan" color="white"/>
                        </div>
                        <div id="hero-content" class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell large-5">
                                    <h1>Welcome to Anthem</h1>
                              </div>
                        </div>
                  </div>
            </section>
            <section class="section bottom-content">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb80">
                              <div class="cell large-5 first-column">
                                    <p class="navy-blue-text fs18">Why Anthem is a smart choice across Georgia, the country, or around the world</p>
                                    <p>We serve more than 40 million members nationwide with programs and provider networks that go beyond typical health plans.</p>
                              </div>
                              <div class="cell large-5 medium-offset-2 second-column">
                                    <img src="img/us-map.png"/>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb80">
                              <div class="cell large-6">
                                    <p class="fw600 fs18 mb40">Our members benefit from:</p>
                                    <div class="grid-x grid-padding-x align-top animate__animated animate__fadeIn">
                                          <div class="cell medium-6 mb40">
                                                <h5 class="fw600 fs18 new-blue-text mb8">One of the largest networks in Georgia</h5>
                                                <p>Confidence in finding an in-network doctor<sup>1</sup></p>
                                          </div>
                                          <div class="cell medium-6 mb40">
                                                <h5 class="fw600 fs18 new-blue-text mb8">Coverage when traveling outside the US</h5>
                                                <p>In-network doctors/hospitals in nearly 190 countries and territories worldwide<sup>2</sup></p>
                                          </div>
                                          <div class="cell medium-6 mb40">
                                                <h5 class="fw600 fs18 new-blue-text mb8">National coverage</h5>
                                                <p>Extensive national networks for both the HMO and HRA plans</p>
                                          </div>
                                          <div class="cell medium-6 mb40">
                                                <h5 class="fw600 fs18 new-blue-text mb8">Discounted rates with in-network providers</h5>
                                          </div>
                                    </div>
                              </div>
                              <div class="cell large-5 medium-offset-1 second-column">
                                    <div class="welcome-side-box">
                                          <h5 class="new-blue-text fw700 mb32 fs16">Anthem's Plan Options provide support 24/7, including:</h5>
                                          <div class="grid-x grid-padding-x mb24">
                                                <div class="cell medium-6 mb24">
                                                      <img src="~@/assets/img/ongo-icon.png" width="40" class="coral">
                                                      <p>Health information on the go via the Sydney<sup>SM</sup> Health mobile app</p>
                                                </div>
                                                <div class="cell medium-6 mb24">
                                                      <img src="~@/assets/img/www-icon.png" width="40" class="coral">
                                                      <p>A dedicated website at <a href="https://anthem.com/shbp" target="_blank" class="navy-blue-text underline" @click="track('Link', 'anthem.com/shbp - Welcome To Anthem')">anthem.com/shbp</a></p>
                                                </div>
                                                <div class="cell medium-6">
                                                      <img src="img/head-new-icon.png" width="40" class="coral">
                                                      <p>Behavioral Health Resource Center</p>
                                                </div>
                                                <div class="cell medium-6">
                                                      <img src="~@/assets/img/nurse-online-icon.png" width="40" class="coral">
                                                      <p>Registered nurses via our NurseLine</p>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-top animate__animated animate__fadeIn mb40">
                              <div class="cell large-12">
                                    <p class="fs12">1. Network subject to change. 2. Coverage with the Blue Cross Blue Shield Global Core Program, GeoBlue website, more than 20 years as a leader in international healthcare (accessed November 2019): about.geo-blue.com. GeoBlue is the trade name for the international health insurance program of Worldwide Insurance Services (WIS), an independent licensee of Blue Cross Blue Shield Association. Blue Cross Blue Shield Association is an association of independent Blue Cross and Blue Shield companies. Blue Cross Blue Shield Global is a brand owned by Blue Cross Blue Shield Association.</p>
                              </div>
                        </div>
                  </div>
            </section>
            <NextButton link="/checklist"/>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import NextButton from "../components/NextButton"
      import gaEventMixin from "../mixins/gaEventMixin"
      export default {
            name: "SHBPWelcomeToAnthem",
            data (){
                  return{
                         currMember:this.$store.state.currMember
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {
                  BackButton,
                  NextButton
            },
            mixins: [gaEventMixin]
      }
</script>

<style lang="scss">
      #welcome-to-anthem{
            .hero{
                  background-image: url("~@/assets~@/assets/img/welcome-hero-2024-lg.jpg");
                  background-position: right top;
                  background-repeat: no-repeat;
                  background-size: auto;
                  position: relative;
                  
                  #hero-content{
                        h1{
                              margin-bottom: 5rem;
                              @media only screen and (max-width:640px){
                                    margin-bottom: 0;
                              }
                        }
                  }
                  @media only screen and (max-width: 640px){
                        //background-position: -440px center;
                  }
            }
            .welcome-side-box{
                  background: rgba(105, 179, 231, 0.3);
                  border-radius: 24px;
                  padding: 40px;
                  border-radius: 24px;
                  h5{
                        font-size: 1.25rem;
                  }
                  p{
                        font-size: 0.875rem;
                  }
                  img{
                        margin-bottom: 8px;
                  }
            }
      }
</style>

<template>
      <div id="plan-options-current">
            <section class="section hero">
                  <div class="grid-container">
                        <BackButton link="/one-more-question-current"/>
                        <div id="hero-content" class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn checklist-hero-content">
                              <div class="cell large-10">
                                    <h1 class="fw600">Your Anthem Plan<br/> Options for 2025</h1>
                              </div>
                        </div>
                  </div>
            </section>
            <section class="section bottom-content">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell large-5 first-column">
                                    <p class="fw400 fs18 bullet ml24">Select the Plan Option and tier you are considering changing to and would like to remember to choose during the Open Enrollment period.</p>
                              </div>
                              <div class="cell large-4 medium-offset-2 second-column">
                                    <div class="grid-x grid-padding-x small-up-2 align-middle">
                                          <div class="cell">
                                                <button class="plan-btn" :class="{ active : activePlan == 'HMO' }" @click="planSelect('HMO')">HMO</button>
                                          </div>
                                          <div class="cell">
                                                <button class="plan-btn" :class="{ active : activePlan == 'HRA Gold' }" @click="planSelect('HRA Gold')">HRA <br/>Gold</button>
                                          </div>
                                          <div class="cell">
                                                <button class="plan-btn" :class="{ active : activePlan == 'HRA Silver' }" @click="planSelect('HRA Silver')">HRA <br/>Silver</button>
                                          </div>
                                          <div class="cell">
                                                <button class="plan-btn" :class="{ active : activePlan == 'HRA Bronze' }" @click="planSelect('HRA Bronze')">HRA <br/>Bronze</button>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
            <section id="check-btn-bottom">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x">
                              <div class="cell medium-12">
                                    <button class="button check-btn large" @click="storeData()">Next</button>
                              </div>
                        </div>
                  </div>
            </section>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import gaEventMixin from "../mixins/gaEventMixin"
      export default {
            name: "SHBPPlanOptionsCurrent",
            data (){
                  return{
                        activePlan: this.$store.state.planSelect,
                  }
            },
            methods:{
                  planSelect(name){
                        this.$store.commit("changePlan", name)
                        this.track("Choice", name + " Coverage")
                        this.activePlan = name
                        localStorage.setItem("antPlan", name)
                  },
                  storeData(){
                        let planSelected = this.$store.state.planSelect
                        let message = "Sorry, but you must select a plan before proceeding to the next step."
                        if(!planSelected){
                              this.$swal({
                                    html: message,
                                    showCancelButton: false
                              })
                        }
                        else{ 
                              //const routes = this.$store.state.routeName
                              this.$router.push("/summary-options-current")
                              //localStorage.setItem("antRouteNames", JSON.stringify(routes))
                        }
                  }
            },
            components: {BackButton},
            mixins: [gaEventMixin]
      }
</script>

<style lang="scss">
      #plan-options-current{
            .hero{
                  background-image: none;
                  background-color: #ffffff;
                  padding-bottom: 0px;
                  #hero-content{
                        min-height: 300px;
                        h1{
                              color: #002E58;
                              font-size: 3rem !important;
                              @media only screen and (max-width:640px){
                                    margin-bottom: 0px;
                              }
                              @media only screen and (max-width: 639px) and (min-width: 360px){
                                    font-size: 8.3vw !important;
                              }
                        }
                        @media only screen and (max-width:640px){
                              min-height: 160px;
                        }
                  }
                  
                  &:before{
                        display: none;
                  }
            }
            .bottom-content{
                  padding-top: 0px;
            }
            .plan-btn{
                  background-color: #E1EDFF;
                  border-radius: 20px;
                  padding: 1rem;
                  font-size: 1.5rem;
                  font-weight: 700;
                  color: #1A3673;
                  width: 158px;
                  height: 158px;
                  margin-bottom: 1rem;
                  transition: all 0.2s ease-in-out 0s;
                  cursor: pointer;
                  &:hover, &.active{
                        background-color: #1A3673;
                        color: #ffffff;
                  }
            }
      }
</style>

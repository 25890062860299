<template>
      <div id="keeping-plan">
            <section class="section hero">
                  <div class="grid-container">
                        <BackButton link="/considering-coverage" color="white"/>
                        <div id="hero-content" class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell medium-6">
                                    <h1>Let's find the right information for you</h1>
                              </div>
                              <div class="cell medium-4 medium-offset-2">
                                    <p>Help us make this experience fit your expectations and needs by answering a few basic questions.</p>
                              </div>
                        </div>
                  </div>
            </section>
            <section class="section bottom-content">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn">
                              <div class="cell medium-5">
                                    <h3 class="navy-blue-text fs18 fw400">Are you considering keeping your current plan?</h3>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn mb8">
                              <div class="cell medium-5">
                                    <div class="grid-x grid-padding-x">
                                          <label class="radio cell auto">
                                                <div class="grid-x grid-padding-x">
                                                      <div class="cell shrink">
                                                            <input type="radio" value="Yes" v-model="keepPlan"/>
                                                      </div>
                                                      <div class="cell auto">
                                                            <span>Yes</span>
                                                      </div>
                                                </div>
                                          </label>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn mb8">
                              <div class="cell medium-5">
                                    <div class="grid-x grid-padding-x">
                                          <label class="radio cell auto">
                                                <div class="grid-x grid-padding-x">
                                                      <div class="cell shrink">
                                                            <input type="radio" value="No" v-model="keepPlan"/>
                                                      </div>
                                                      <div class="cell auto">
                                                            <span>No</span>
                                                      </div>
                                                </div>
                                          </label>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn">
                              <div class="cell medium-5">
                                    <div class="grid-x grid-padding-x">
                                          <label class="radio cell auto">
                                                <div class="grid-x grid-padding-x">
                                                      <div class="cell shrink">
                                                            <input type="radio" value="Not Sure" v-model="keepPlan"/>
                                                      </div>
                                                      <div class="cell auto">
                                                            <span>Not Sure</span>
                                                      </div>
                                                </div>
                                          </label>
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
            <section id="check-btn-bottom">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x">
                              <div class="cell medium-12">
                                    <button class="button check-btn large" @click="storeData()">Next</button>
                              </div>
                        </div>
                  </div>
            </section>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import gaEventMixin from "../mixins/gaEventMixin"
      export default {
            name: "SHBPKeepingPlan",
            data (){
                  return{
                        keepPlan: this.$store.state.keepPlan
                  }
            },
            methods:{
                  storeData(){
                        const keepPlan = this.keepPlan
                        if((!keepPlan) ){
                              let message = 'Please make a choice below.'
                              this.$swal({
                                    html: message,
                                    showCancelButton: false
                              })
                        }
                        else{
                              //const routes = this.$store.state.routeName
                              this.$store.commit("changeKeepPlan", keepPlan)
                              //this.track("Choice", keepPlan + " Coverage")
                              this.$router.push("/welcome-to-anthem")
                              localStorage.setItem("antKeepPlan", keepPlan)
                              //localStorage.setItem("antRouteNames", JSON.stringify(routes))
                        }
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {BackButton},
            mixins: [gaEventMixin]
      }
</script>

<style lang="scss">
      #keeping-plan{
            .hero{
                  background-image: none;
                  h1, p{
                        color: #ffffff !important;
                  }
                  #hero-content{
                        h1{
                              @media only screen and (max-width:640px){
                                    margin-top: 1.5rem;
                              }
                        }
                  }
            }
      }
</style>

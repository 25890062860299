<template>
    <TopBar />
    <transition name="router-anim" enter-active-class="animate__animated animate__fadeIn animate__fast" leave-active-class="animate__animated animate__fadeOut animate__fast" mode="out-in">
      <router-view />
    </transition>
</template>

<script>
  import TopBar from "./components/TopBar"
  export default {
    name: "SHBP",
    metaInfo: {
      meta: [
        {
          name: "description",
          content: "Welcome to the 2024 Anthem Blue Cross and Blue Shield interactive health plan guide; Healthcare innovation with a human touch"
        }
      ]
    },
    components: {
      TopBar
    }
  };
</script>

<style lang="scss">
      @font-face {
            font-family: "Montserrat";
            font-weight: 300;
            src: url("~@/assets/fonts/Montserrat-Light.ttf");
      }
      @font-face {
            font-family: "Montserrat";
            font-weight: normal;
            src: url("~@/assets/fonts/Montserrat-Regular.ttf");
      }
      @font-face {
            font-family: "Montserrat";
            font-weight: 500;
            src: url("~@/assets/fonts/Montserrat-Medium.ttf");
      }
      @font-face {
            font-family: "Montserrat";
            font-weight: 600;
            src: url("~@/assets/fonts/Montserrat-SemiBold.ttf");
      }
      @font-face {
            font-family: "Montserrat";
            font-weight: 700;
            src: url("~@/assets/fonts/Montserrat-Bold.ttf");
      }
      @font-face {
            font-family: "Montserrat";
            font-weight: 900;
            src: url("~@/assets/fonts/Montserrat-Black.ttf");
      }

body {
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif !important;
  font-weight: normal !important;
}

.grid-container {
  max-width: 70rem !important;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif !important;
  margin-bottom: 32px;
  font-size: 38px;
  font-weight: 600;
}

p {
  margin-bottom: 48px !important;
  color: #32383d;
}

a {
  transition: all 0.2s ease-in-out 0s;
  color: #0079c2 !important;
}

sup {
  top: -0.9em !important;
}

sub,
sup {
  font-size: 50% !important;
}

.fa,
.fas,
.far {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.bullet{
  position: relative;
}
.bullet::before{
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 7px;
  left: -25px;
  background-color: #F2695A;
}

.text-left {
  text-align: left !important;
}

.new-blue-text{
  color: #1355E9 !important;
  font-weight: 700;
}
.blue-text {
  color: #0079c2 !important;
  font-weight: 600;
}

.bluest-text {
  color: #005097 !important;
  font-weight: 600;
}

.baby-blue-text {
  color: #69b3e7;
  font-weight: 600;
}

.baby-blue-blue-text {
  color: #e1edff;
  font-weight: 700;
}

.dark-blue-text {
  color: #002e58 !important;
  font-weight: 600;
}
.navy-blue-text {
  color: #1A3673 !important;
  font-weight: 600;
}

.new-dark-blue-text {
  color: #0a357c;
}

.light-blue-text {
  color: #0e63af !important;
  font-weight: 400 !important;
}

.white-text {
  color: #ffffff !important;
}
.black-text {
  color: #32383d !important;
}
.coral{
  filter: brightness(0) saturate(100%) invert(37%) sepia(47%) saturate(617%) hue-rotate(318deg) brightness(126%) contrast(109%);
}

.underline {
  text-decoration: underline !important;
}

.text-cent {
  text-align: center !important;
}

.lh1-1 {
  line-height: 1.1 !important;
}

.fs10 {
  font-size: 0.5rem !important;
}

.fs12 {
  font-size: 0.75rem !important;
}

.fs14 {
  font-size: 0.875rem !important;
}

.fs16 {
  font-size: 1rem !important;
}

.fs18 {
  font-size: 1.125rem !important;
}

.fs20 {
  font-size: 1.25rem !important;
}

.fs24 {
  font-size: 1.5rem !important;
}

.fs28 {
  font-size: 1.75rem !important;
}

.fs32 {
  font-size: 2rem !important;
}

.fw300 {
  font-weight: 300 !important;
}

.fw400 {
  font-weight: 400 !important;
}

.fw500 {
  font-weight: 500 !important;
}

.fw600 {
  font-weight: 600 !important;
}

.fw700 {
  font-weight: 700 !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

@for $i from 0 through 15 {
  //Margin
  .m#{$i * 8} {
    margin: #{$i * 8}px !important;
  }

  .mt#{$i * 8} {
    margin-top: #{$i * 8}px !important;
  }

  .mr#{$i * 8} {
    margin-right: #{$i * 8}px !important;
  }

  .mb#{$i * 8} {
    margin-bottom: #{$i * 8}px !important;
  }

  .ml#{$i * 8} {
    margin-left: #{$i * 8}px !important;
  }

  //Padding
  .p#{$i * 8} {
    padding: #{$i * 8}px !important;
  }

  .pt#{$i * 8} {
    padding-top: #{$i * 8}px !important;
  }

  .pr#{$i * 8} {
    padding-right: #{$i * 8}px !important;
  }

  .pb#{$i * 8} {
    padding-bottom: #{$i * 8}px !important;
  }

  .pl#{$i * 8} {
    padding-left: #{$i * 8}px !important;
  }
}

.section {
  padding: 80px 0px;
  &.bottom-content {
    margin-bottom: 120px;

    @media only screen and (max-width: 640px) {
      padding-top: 40px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.table-container {
  @media only screen and (max-width: 640px) {
    overflow-x: auto;
  }
}

.swal2-styled.swal2-confirm {
  outline: none !important;
  box-shadow: none !important;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel,
.swal2-styled:focus {
  outline: none !important;
  box-shadow: none !important;
}

.swal2-html-container {
  font-size: 1rem !important;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif !important;
  font-weight: 400;
  line-height: 1.6;
  color: #c44748;
  text-align: center;
}
.swal2-container.swal2-backdrop-show {
  background-color: rgba(0, 0, 0, .4);
}
input::placeholder {
  color: #32383d;
  opacity: 0.65; /* Firefox */
}

// HERO BANNER STYLES
.hero {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #1355E9;
  margin-top: 68px;
  padding: 100px 0px 80px 0px;
  
  #hero-content {
    min-height: 300px;
    @media only screen and (max-width: 959px) and (min-width: 360px) {
      padding: 40px 20px 20px 20px;
      min-height: 280px;
      align-items: end;
    }
  }
  .hollow.button {
    border: solid 4px #ffffff;
    color: #ffffff;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0px;
    border-radius: 16px;

    &:hover {
      background-color: #ffffff;
      color: #012169;
    }

    @media only screen and (max-width: 1440px) and (min-width: 960px) {
      padding: 0.85em 0.65em;
    }
  }
  
  @media only screen and (max-width: 1440px){
    background-size: contain !important;
  }
  @media only screen and (max-width: 640px){
    background-size: cover !important;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
    }
  }
  @media only screen and (max-width: 959px) and (min-width: 360px) {
    padding: 40px 0px 40px 0px;
    margin-top: 61px;
  }
}

// FORM STYLES
label {
  font-size: 1rem !important;
}

input {
  background-color: #f1f5f6;
  padding: 14px 16px 14px 24px;
  font-size: 20px !important;
  border: solid 2px #0079c2;
  border-radius: 32px;
  margin: 0 auto;
  width: 100%;
  color: #32383d;
}

input[type="radio"] {
  transform: scale(1.75);
  margin-top: 8px;
  margin-left: 2px;
  margin-right: 4px;
}

::placeholder {
  color: #777777;
}

// BOTTOM BUTTON STYLES
#check-btn-bottom {
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.1);
  padding: 20px 0px;
  position: relative;
  .check-btn {
    background-color: #1A3673;
    width: 330px;
    border-radius: 30px;
    display: block;
    font-size: 1rem;
    font-weight: 700;
    margin: 0 auto;
    color: #ffffff !important;
    &:hover {
      background-color: #1369b5;
    }
  }

  &:before {
    display: none;
    content: "";
    background-size: 28px 16px;
    width: 28px;
    height: 16px;
    animation: MoveUpDown 4s linear infinite;
    position: absolute;
    top: -36px;
    left: 49.5%;
    transform: translateX(-50%);
  }
}

#next-btn-bottom {
  position: relative;

  &:before {
    display: none;
    content: "";
    background-size: 28px 16px;
    width: 28px;
    height: 16px;
    animation: MoveUpDown 4s linear infinite;
    position: absolute;
    top: -36px;
    left: 49.5%;
    transform: translateX(-50%);
  }
}

@keyframes MoveUpDown {

  0%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    transform: translateY(-10px);
    opacity: 0.5;
  }
}

#shbp-app {
  h1 {
    color: #ffffff;
    font-weight: 700;
    font-size: 2.75rem !important;
    line-height: 1.125;
    margin-bottom: 1rem;

    span {
      color: #0079c2;
    }

    @media only screen and (max-width: 1600px) and (min-width: 960px) {
      font-size: 4vw !important;
      line-height: 4vw !important;
    }

    @media only screen and (max-width: 959px) and (min-width: 640px) {
      font-size: 6vw !important;
      line-height: 6vw !important;
    }

    @media only screen and (max-width: 639px) and (min-width: 360px) {
      font-size: 8.3vw !important;
      line-height: 8.3vw !important;
    }
  }

  p {
    margin-bottom: 1.125rem !important;
    font-size: 1rem;
    line-height: 1.5;
    color: #32383d;
  }

  ol {
    list-style-type: none;

    li {
      counter-increment: customlistcounter;
      font-size: 0.688rem;
      margin-bottom: 4px;

      &::before {
        content: counter(customlistcounter) " ";
      }
    }
  }

  #main {
    &.reset {
      height: inherit;
      margin-top: 32vh;

      #content {
        min-height: inherit;
        padding-bottom: 45px;
      }

      @media only screen and (max-height: 740px) {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }

    &.section {
      @media only screen and (max-height: 740px) {
        height: inherit;
        padding-top: 24vh;
        padding-bottom: 45px;
      }
    }
  }

  #content {
    min-height: calc(100vh - 45px);
  }

  .content {
    color: #32383d;
    font-size: 2rem;
    line-height: 1.5;

    @media only screen and (max-width: 1600px) and (min-width: 960px) {
      font-size: 2vw !important;
      line-height: 2.875vw !important;
    }
  }

  .baby-blue {
    color: #0079c2;
  }

  .questions-side {
    background-color: #d2e8f7;
    padding: 40px 48px;
    border-left: solid 8px #0079c2;
    color: #0079c2;

    h6 {
      font-size: 1rem;
      font-weight: 600;
    }

    p {
      font-size: 1rem;
      color: #0079c2;
      margin-bottom: 0px !important;
    }

    @media only screen and (max-width: 1440px) and (min-width: 960px) {
      padding: 24px 24px;
    }
  }
}

.input-toggler {
  display: none;
}

.menu-toggler {
  position: absolute;
  right: 8px;
  top: 50%;
  width: 72px;
  height: 60px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  margin: 0 !important;
  transform: translateY(-50%);
}

.menu-line-container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  .menu-toggler-line {
    display: block;
    width: 52%;
    height: 4px;
    background-color: #32383d;
    margin: 0 auto 5px;
    position: relative;
    transition: all 0.4s ease-out;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.input-toggler:checked~.menu-toggler .menu-toggler-line {
  top: 7px;
  transform: rotate(45deg) translateY(0px);
  background-color: #ffffff;
}

.input-toggler:checked~.menu-toggler .menu-toggler-line:nth-child(2) {
  display: none;
}

.input-toggler:checked~.menu-toggler .menu-toggler-line:nth-child(3) {
  top: -1px;
  transform: rotate(135deg) translateY(1px);
  background-color: #ffffff;
}

.nav {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.nav ul {
  width: 100%;
}

.nav ul li {
  width: 100%;
  text-align: center;
}

.nav ul li a {
  display: inline-block;
  font-size: 36px;
  color: #fff;
  text-transform: uppercase;
}

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #1A3673;
  height: 100vh;
  width: 100%;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-100%);
  transition: all 0.4s ease-out;

  .sidebar-logo {
    position: absolute;
    top: 12%;

    img {
      display: block;
    }
  }

  .mobile-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 40px;
      text-align: center;

      .menu-link {
        text-align: center;
        display: block;
        font-weight: 500;
        color: #ffffff !important;
        font-size: 6vw !important;
        line-height: 6.4vw !important;
        -webkit-transition: all 0.25s ease-out !important;
        transition: all 0.25s ease-out !important;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.input-toggler:checked~.sidebar {
  transform: translateX(0%);
  opacity: 1;
}
</style>

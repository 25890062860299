<template>
      <section id="header" :class="currentRouteName">
            <div class="grid-container">
                  <div class="grid-x grid-padding-x">
                        <div class="cell shrink">
                              <router-link to="/">
                                    <img alt="Anthem SHBP" src="img/logo-blue.png" width="220" />
                              </router-link>
                        </div>
                        <div class="cell auto show-for-medium">
                              <ul class="menu align-right">
                                    <li>
                                          <router-link to="/important-contacts">Important Contacts</router-link>
                                    </li>
                                    <li>
                                          <router-link to="/total-health">Personal Health Solutions</router-link>
                                    </li>
                              </ul>
                        </div>
                  </div>
            </div>
            <div class="mobile-section">
                  <input ref="toggleCheck" type="checkbox" id="toggle" class="input-toggler" v-model="checked" />
                  <label for="toggle" class="menu-toggler hide-for-medium">
                        <div class="menu-line-container">
                              <span class="menu-toggler-line"></span>
                              <span class="menu-toggler-line"></span>
                              <span class="menu-toggler-line"></span>
                        </div>
                  </label>
                  <aside class="sidebar">
                        <div class="sidebar-logo" @click="hideMenu()">
                              <router-link to="/" class="menu-link"><img src="/img/logo-white.png"
                                          width="200" /></router-link>
                        </div>
                        <ul class="mobile-menu">
                              <li @click="hideMenu()">
                                    <router-link to="/" class="menu-link">Home</router-link>
                              </li>
                              <li @click="hideMenu()">
                                    <router-link to="/important-contacts" class="menu-link">Important<br />
                                          Contacts</router-link>
                              </li>
                              <li @click="hideMenu()">
                                    <router-link to="/total-health" class="menu-link">Personal<br />
                                          Health<br />
                                          Solutions</router-link>
                              </li>
                        </ul>
                  </aside>
            </div>
      </section>
</template>

<script>
export default {
      name: "TopBar",
      data() {
            return {
                  scroll: "",
                  checked: false,
            };
      },
      created() {
            window.addEventListener("scroll", this.scrollDown);
      },
      destroyed() {
            window.removeEventListener("scroll", this.scrollDown);
      },
      methods: {
            scrollDown() {
                  let width = window.innerWidth;
                  let currentScroll = window.pageYOffset;
                  if (width <= 640) {
                        currentScroll = window.pageYOffset + 300;
                  }
                  if (currentScroll >= 620) {
                        this.scroll = "scroll-down animate__fadeIn animate__faster";
                  } else {
                        this.scroll = "";
                  }
            },
            hideMenu() {
                  this.$refs.toggleCheck.checked = false;
                  this.checked = false;
            },
      },
      computed: {
            currentRouteName() {
                  let routeHome = "SHBP Home";
                  if (this.$route.name == routeHome) {
                        return false;
                  } else {
                        return "internal-page-header";
                  }
            },
      },
};
</script>

<style lang="scss">
#header {
      background-color: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      padding: 16px;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      z-index: 5;

      .grid-container {
            max-width: 82rem !important;
      }

      &.scroll-down {
            position: fixed;
            width: 100%;
      }

      .menu {
            a {
                  color: #231E33 !important;
                  font-weight: 600;
                  padding: 0.55rem 1.5rem;
                  font-size: 1.125rem;

                  &:hover {
                        opacity: 0.8;
                  }

                  @media only screen and (max-width: 1440px) and (min-width: 960px) {
                        padding: 0.55rem 1rem;
                  }
            }
      }
}
</style>

<template>
      <div id="intro">
            <section class="section hero">
                  <div class="grid-container">
                        <BackButton link="/" color="white" />
                        <div id="hero-content"
                              class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell large-6">
                                    <h1>Bringing the<br> best together.</h1>
                              </div>
                        </div>
                  </div>
            </section>
            <section class="section bottom-content">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell medium-5 first-column">
                                    <p>Health requires strong connection &mdash; within yourself and your community.</p>
                                    <p>Anthem is here — and has been for over 80 years — to connect you with the care you need. Our large network takes root in Georgia and spans to doctors, therapists and whole-health specialists all over America.</p>
                              </div>
                              <div class="cell medium-5 medium-offset-2 second-column">
                                    <p>With Anthem, you have whole-health support that sets you up to make better decisions and reinforces the healthy choices you're already making.</p>
                                    <p class="new-blue-text">Thank you for considering Anthem.</p>
                              </div>
                        </div>
                  </div>
            </section>
            <NextButton link="/name" />
      </div>
</template>

<script>
import BackButton from "../components/BackButton"
import NextButton from "../components/NextButton"
export default {
      name: "SHBPIntro",
      data() {
            return {
            }
      },
      mounted() {
            window.scrollTo(0, 0)
      },
      components: {
            BackButton,
            NextButton
      }
}
</script>

<style lang="scss">
#intro {
      .hero {
            background-image: url("~@/assets~@/assets/img/intro-hero-2024-lg.jpg");
            background-position: right top;
            background-repeat: no-repeat;
            background-size: auto;

            @media only screen and (max-width: 640px) {
                  //background-position: -383px center;
            }
      }
}
</style>

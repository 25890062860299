<template>
      <div id="welcome-new">
            <section class="section hero">
                  <div class="grid-container">
                        <BackButton link="/member" color="white"/>
                        <div id="hero-content" class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell medium-12">
                                    <h1>Welcome, {{ firstName }}</h1>
                              </div>
                        </div>
                  </div>
            </section>
            <section class="section bottom-content">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell large-5 first-column">
                                    <p class="navy-blue-text fs18 fw400">We're glad you're considering an Anthem Plan Option for 2025.</p>
                                    <p>This year, the Open Enrollment selection period is:<br/><a href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20241015%2F20241108&details=This%20year%2C%20the%20Open%20Enrollment%20selection%20period%20is%20October%2015%2C%202024%20-%0ANovember%208%2C%202024.%20&text=Anthem%20Open%20Enrollment" class="navy-blue-text underline" target="_blank" @click="track('Link', 'Add To Calendar - Welcome New')">October 15 - November 8, 2024</a></p>
                              </div>
                              <div class="cell large-5 medium-offset-2 second-column">
                                    <p class="bullet ml24">The estimated length of your experience on this site will be: <span class="navy-blue-text">10 minutes</span></p>
                              </div>
                        </div>
                  </div>
            </section>
            <NextButton link="/considering-coverage-new"/>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import NextButton from "../components/NextButton"
      export default {
            name: "SHBPWelcomeNew",
            data (){
                  return{
                        firstName: this.$store.state.firstName,
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {
                  BackButton,
                  NextButton
            }
      }
</script>

<style lang="scss">
      #welcome-new{
            .hero{
                  background-image: none;
                  h1, p{
                        color: #ffffff !important;
                  }
                  #hero-content{
                        h1{
                              margin-bottom: 8rem;
                              @media only screen and (max-width:640px){
                                    margin-bottom: 96px;
                              }
                        }
                  }
            }
      }
</style>

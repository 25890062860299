<template>
      <div id="name">
            <section class="section hero">
                  <div class="grid-container">
                        <BackButton link="/intro" color="white" />
                        <div id="hero-content"
                              class="grid-x grid-padding-x align-top animate__animated animate__fadeIn">
                              <div class="cell large-5">
                                    <h1>Welcome</h1>
                              </div>
                        </div>
                  </div>
            </section>
            <section class="section bottom-content">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn">
                              <div class="cell medium-4">
                                    <h3>What's your first name?</h3>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn">
                              <div class="cell medium-4">
                                    <input v-model="firstName" placeholder="Type your name here" />
                              </div>
                        </div>
                  </div>
            </section>
            <section id="check-btn-bottom">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x">
                              <div class="cell medium-12">
                                    <button class="button check-btn large" @click="storeData()">Continue</button>
                              </div>
                        </div>
                  </div>
            </section>

      </div>
</template>

<script>
import BackButton from "../components/BackButton"
import gaEventMixin from "../mixins/gaEventMixin"
export default {
      name: "SHBPName",
      data() {
            return {
                  firstName: this.$store.state.firstName,
            }
      },
      methods: {
            storeData() {
                  const firstName = this.firstName
                  if ((!firstName || firstName == "" || firstName.length <= 1)) {
                        let message = 'Please make sure the name you have entered is correct and that all fields have been filled out.'
                        this.$swal({
                              html: message,
                              showCancelButton: false
                        })
                  }
                  else {
                        this.$store.commit("changeName", firstName)
                        this.$router.push("/member")
                        localStorage.setItem("antName", firstName)
                  }
            }
      },
      mounted() {
            window.scrollTo(0, 0)
      },
      components: { BackButton },
      mixins: [gaEventMixin]
}
</script>

<style lang="scss">
#name {
      .hero {
            background-image: url("~@/assets~@/assets/img/name-hero-2024-lg.jpg");
            background-position: right top;
            background-repeat: no-repeat;
            background-size: auto;

            #hero-content {
                  h1 {
                        color: #ffffff;

                        @media only screen and (min-width:641px) {
                              margin-top: 48px;
                        }

                        @media only screen and (max-width:640px) {
                              margin-top: 32px;
                        }

                        @media only screen and (max-width:959px) and (min-width: 360px) {
                              color: #ffffff;
                        }
                  }

                  @media only screen and (max-width:959px) and (min-width: 360px) {
                        //align-items: start;
                  }
            }
            .back-btn {
                  @media only screen and (max-width:959px) and (min-width: 360px) {
                        color: #ffffff !important;
                        border: solid 1px #ffffff;

                        &:before {
                              background-image: url("~@/assets~@/assets/img/arrow-left-icon.png");
                              filter: brightness(0) invert(1);
                        }
                  }
            }
      }

      h3 {
            color: #0E63AF;
            font-size: 1.125rem;
            font-weight: 400;
            margin-bottom: 0.5rem;

            @media only screen and (max-width:640px) {
                  text-align: left;
            }
      }
}
</style>

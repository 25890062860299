<template>
      <div id="back-btn-top">
            <router-link :to="link" class="back-btn" :class="color"></router-link>
      </div>
</template>

<script>
export default {
      name: "BackButton",
      props: {
            link: {
                  type: String,
                  required: true,
            },
            color: {
                  type: String,
                  required: false,
            },
      },
};
</script>

<style lang="scss">
.back-btn {
      display: block;
      font-size: 1rem;
      position: absolute;
      top: -3rem;
      padding: 0.4rem 0.5rem;
      border-radius: 50%;
      border: solid 1px #1A3673;
      z-index: 4;
      width: 32px;
      height: 31px;

      i {
            display: block;
      }

      &:hover {
            color: #1369b5 !important;
            border-color: #1369b5;
      }

      &.white {
            color: #ffffff !important;
            border: solid 1px #ffffff;

            &:before {
                  background-image: url("~@/assets/img/arrow-left-icon.png");
                  filter: brightness(0) invert(1);
            }
      }

      &:before {
            content: "";
            background-image: url("~@/assets/img/arrow-left-icon.png");
            filter: brightness(0) saturate(100%) invert(16%) sepia(37%) saturate(3431%) hue-rotate(205deg) brightness(92%) contrast(90%);
            background-size: 15px 13px;
            width: 15px;
            height: 13px;
            position: absolute;
            top: 50%;
            left: 49.5%;
            transform: translate(-50%, -50%);
      }

      @media only screen and (max-width: 640px) {
            top: 0;
            left: 2rem;
      }
}
</style>

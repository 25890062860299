import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import FoundationCSS from "foundation-sites/dist/css/foundation.min.css";
import AnimateCSS from "animate.css/animate.min.css";
import VueSplideCSS from "@splidejs/splide/dist/css/themes/splide-default.min.css";
import VueGtag from "vue-gtag";
import VueSimpleAlert from "vue3-simple-alert";
import VueSweetalert2 from 'vue-sweetalert2';
import qs from "qs";
import store from "./store";
import VueSplide from "@splidejs/vue-splide";

createApp(App)
    .use(store)
    .use(router)
    .use(FoundationCSS)
    .use(AnimateCSS)
    .use(VueSplideCSS)
    .use(VueGtag, {
        config: { 
            id: "G-85D3C2GLV5",
            params: {
                send_page_view: false
            }
        }
    }, router)
    .use(VueSimpleAlert)
    .use(VueSweetalert2)
    .use(qs)
    .use(VueSplide)
.mount("#shbp-app");

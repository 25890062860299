<template>
      <div id="livehealth-online">
            <section class="section hero">
                  <div class="grid-container">
                        <BackButton link="/sydney-health" color="white"/>
                        <div id="hero-content" class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell large-6">
                                    <h1>LiveHealth<br class="hide-for-medium"> Online</h1>
                                    <!--<p class="fs18">Healthcare needs<br class="hide-for-medium"/> don’t always coincide<br class="hide-for-medium"/> with working hours</p>-->
                              </div>
                        </div>
                  </div>
            </section>
            <section class="section bottom-content">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb24">
                              <div class="cell large-9 first-column">
                                    <p class="fs18">LiveHealth Online allows you to speak with a doctor or health professional
                                          from the comfort of your home or the convenience of your office. Out-of-pocket expenses will depend on the type of Plan Option you select.</p>
                                    
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-top animate__animated animate__fadeIn mb32">
                              <div class="cell medium-3 mb24">
                                    <img src="img/clock-icon.png" class="mb8" width="41"/>
                                    <h5 class="navy-blue-text fs18 mb8">See</h5>
                                    <p>a board-certified medical doctor in a few minutes without an appointment, 24/7.</p>
                              </div>
                              <div class="cell medium-3 mb24">
                                    <img src="img/calendar-icon.png" class="mb8" width="52"/>
                                    <h5 class="navy-blue-text fs18 mb8">Visit</h5>
                                    <p>with a licensed therapist, psychologist or psychiatrist from the privacy of your own home.<sup>1</sup></p>
                              </div>
                              <div class="cell medium-3 mb24">
                                    <img src="img/baby-icon.png" class="mb8" width="34"/>
                                    <h5 class="navy-blue-text fs18 mb8">Receive</h5>
                                    <p>video-based lactation and postpartum support after the birth of a child.</p>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb24">
                              <div class="cell large-6 first-column ml24">
                                    <p class="fs18 bullet">Doctors can answer questions, make diagnoses and even prescribe basic medications when needed.<sup>2</sup> Visit <a href="https://Livehealthonline.com" class="navy-blue-text underline" target="_blank" @click="track('Link', 'Livehealthonline.com - Live Health Online')">Livehealthonline.com</a> for more information.</p>
                              </div>
                        </div>

                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb24">
                              <div class="cell large-12 first-column">
                                    <p class="fs12">1. LiveHealth Online Psychology appointments are available 7 days a week, 7 a.m. to 11 p.m. coast-to-coast, for adults and children age 10 or older. Psychiatrist appointments are subject to availability. 2. Prescription availability is defined by physician judgment and state regulations.</p>
                              </div>
                        </div>

                        
                  </div>
            </section>
            <NextButton link="/dermatologist"/>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import NextButton from "../components/NextButton"
      import gaEventMixin from "../mixins/gaEventMixin"
      export default {
            name: "SHBPLiveHealthOnline",
            data (){
                  return{
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {
                  BackButton,
                  NextButton
            },
            mixins: [gaEventMixin]
      }
</script>

<style lang="scss">
      #livehealth-online{
            .hero{
                  background-image: url("~@/assets~@/assets/img/livehealth-hero-2024-lg.jpg");
                  background-position: right top;
                  background-repeat: no-repeat;
                  background-size: auto;
                  position: relative;
                  
                  #hero-content{
                        h1{
                              color: #ffffff;
                              margin-bottom: 2.5rem;
                              @media only screen and (max-width:640px){
                                    margin-bottom: 0 !important;
                              }
                        }
                  }
                  @media only screen and (max-width:640px){
                        //background-position: -254px center;
                  }
            }
            img{
                  filter: invert(53%) sepia(87%) saturate(3205%) hue-rotate(330deg) brightness(94%) contrast(100%);
            }
      }
</style>

<template>
      <div id="important-contacts">
            <section class="section bottom-content">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb32">
                              <div class="cell large-10">
                                    <h1>Important contact information</h1>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb48">
                              <div class="cell medium-12 table-container">
                                    <table>
                                          <thead>
                                                <tr>
                                                      <th colspan="3" class="th-section">Anthem contact information</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                <tr>
                                                      <td class="new-blue-text" width="30%"><strong>Anthem Blue Cross and Blue Shield (Anthem) </strong></td>
                                                      <td width="33%">855-641-4862 8 a.m. to 8 p.m. ET,<br/> Mon. – Fri. <br/><a href="https://www.anthem.com/shbp/" class="navy-blue-text fw400 underline" target="_blank" @click="track('anthem.com/shbp')">anthem.com/shbp</a></td>
                                                      <td width="37%">Call our Member Services line or visit our website to find answers to your benefit questions, check your HRA or MIA credit balance or get detailed information about your plan.</td>
                                                </tr>
                                                <tr>
                                                      <td class="new-blue-text"><strong>Anthem’s 24/7 NurseLine</strong></td>
                                                      <td>866-787-6361<br/> 24 hours a day/7 days a week</td>
                                                      <td>Registered nurses can answer your health questions any time of the day or night.</td>
                                                </tr>
                                                <tr>
                                                      <td class="new-blue-text"><strong>Total Health, Total You with Anthem</strong></td>
                                                      <td>866-901-0746<br/> 8 a.m. to 6 p.m. ET, Mon. – Fri.</td>
                                                      <td>
                                                            <ul>
                                                                  <li>Telephonic access to clinical health experts</li>
                                                                  <li>Pharmacy Co-Pay/Co-Insurance Waiver Program</li>
                                                                  <li>Future Moms maternity program</li>
                                                            </ul>
                                                      </td>
                                                </tr>
                                                <tr>
                                                      <td class="new-blue-text"><strong>Anthem’s Behavioral Health Resource Center</strong></td>
                                                      <td>855-679-5722<br/> 24 hours a day/7 days a week</td>
                                                      <td>
                                                            <ul class="two-columns">
                                                                  <li>Depression</li>
                                                                  <li>Bipolar disorder</li>
                                                                  <li>Alcohol/drug abuse</li>
                                                                  <li>Anxiety disorders</li>
                                                                  <li>Eating disorders</li>
                                                            </ul>
                                                      </td>
                                                </tr>
                                          </tbody>
                                          <thead>
                                                <tr>
                                                      <th colspan="3" class="th-section">Wellness contact information</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                <tr>
                                                      <td class="new-blue-text"><strong>Be Well SHBP<sup>&reg;</sup>, well-being program, administered by Sharecare</strong></td>
                                                      <td>888-616-6411<br/> 8 a.m. to 8 p.m. ET, Mon. – Fri. <br/><a href="https://bewellshbp.com" class="navy-blue-text fw400 underline" target="_blank" @click="track('bewellshbp.com')">bewellshbp.com</a></td>
                                                      <td>
                                                            <ul>
                                                                  <li>Complete well-being incentive actions</li>
                                                                  <li>Help with improving health through lifestyle changes</li>
                                                            </ul>
                                                      </td>
                                                </tr>
                                          </tbody>
                                          <thead>
                                                <tr>
                                                      <th colspan="3" class="th-section">Pharmacy contact information</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                                <tr>
                                                      <td class="new-blue-text"><strong>CVS Caremark<sup>&reg;</sup></strong></td>
                                                      <td>844-345-3241<br/> 24 hours a day/7 days a week<br/> <a href="https://info.caremark.com/shbp" class="navy-blue-text fw400 underline" target="_blank" @click="track('info.caremark.com/shbp')">info.caremark.com/shbp</a></td>
                                                      <td>Pharmacy benefits—retail and mail order.</td>
                                                </tr>
                                          </tbody>
                                          <thead>
                                                <tr>
                                                      <th colspan="3" class="th-section">Enrollment and eligibility contact information</th>
                                                </tr>
                                          </thead>
                                                <!--<tr>
                                                      <td class="new-blue-text"><strong>LiveHealth Online</strong></td>
                                                      <td>24 hours a day/7 days a week <br/><a href="https://livehealthonline.com" target="_blank" @click="track('ivehealthonline.com')">livehealthonline.com</a></td>
                                                      <td>Connect with a provider 24 hours a day on your computer or mobile device</td>
                                                </tr>-->
                                          <tbody>
                                                <tr>
                                                      <td class="new-blue-text no-border"><strong>SHBP Member Services</strong></td>
                                                      <td class="no-border">800-610-1863<br/>8:30 a.m. to 5:00 p.m. ET, Mon. - Fri.<br/> <a href="https:// www.shbp.georgia.gov" class="navy-blue-text fw400 underline">www.shbp.georgia.gov</a></td>
                                                      <td class="no-border">Questions about eligibility, premiums, or enrollment.</td>
                                                </tr>
                                          </tbody>
                                    </table>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x mb24">
                              <div class="cell medium-12">
                                    <ul>
                                          <li>Anthem Blue Cross and Blue Shield is the trade name of Blue Cross Blue Shield Healthcare Plan of Georgia, Inc. Independent licensee of the Blue Cross and Blue Shield Association. ANTHEM is a registered trademark of Anthem Insurance Companies, Inc.</li>
                                    </ul>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x">
                              <div class="cell small-12">
                                    <router-link to="/intro" class="float-left fw600 fs18 return-link navy-blue-text">Return to guide</router-link>
                              </div>
                        </div>
                  </div>
            </section>
      </div>
</template>

<script>
      export default {
            name: "SHBPImportantContacts",
            data (){
                  return{
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
            }
      }
</script>

<style lang="scss">
      #important-contacts{
            .bottom-content{
                 margin-top: 68px;
                 padding: 100px 0px 80px 0px;
                 @media only screen and (max-width:640px){
                        padding: 40px 20px 80px 20px;
                  }
                 h1{
                  color: #1A3673;
                  margin-top: 3rem;
                  margin-bottom: 4rem;
                  @media only screen and (max-width:640px){
                        margin-top: 4rem;
                        margin-bottom: 2rem;
                  }
                 }
           }
           thead{
                  background-color: #1A3673;
                  color:#ffffff;
                  font-size: 1rem;
                  th{
                        &.th-section{
                              padding: 1rem 1rem;
                        }
                  }
            }
            tbody, tfoot, thead{
                  border:none;
            }
            tbody tr:nth-child(odd){
                  background: none;
            }
            tbody tr:nth-child(even) {
                  background: none;
            }
            tbody{
                  td{
                        font-size: 0.875rem;
                        font-weight: 400;
                        padding: 1.5rem 1rem;
                        border-bottom: solid 1px #000000;
                        &.white-bg{
                              background-color: #ffffff;
                        }
                        ul{
                              list-style-type: disc;
                              li{
                                    font-size: 0.875rem;
                                    font-weight: 400;
                                    padding-left:0px;
                                    margin-bottom: 4px;
                                    margin-left: 18px;
                              }
                              &.two-columns{
                                    columns: 2;
                                    -webkit-columns: 2;
                                    -moz-columns: 2;
                                    column-gap: 40px;
                              }
                        }
                        &.no-border{
                              border: none !important;
                        }
                  }
                  tr{
                        &.row-border-top{
                              border-top: solid 1px #A4B6BE;
                        }
                  }
            }
            ul{
                  list-style-type: none;
                  margin: 0px;
                  li{
                        font-size: 0.75rem;
                        padding-left:0px;
                        position: relative;
                        margin-bottom: 4px;
                  }
            }
            .return-link{
                  position: relative;
                  padding-left: 20px;
                  &:before{
                        content: "";
                        background-image: url("~@/assets/img/arrow-left-icon.png");
                        filter: brightness(0) saturate(100%) invert(14%) sepia(34%) saturate(4633%) hue-rotate(212deg) brightness(90%) contrast(89%);
                        background-size: 15px 13px;
                        width: 15px;
                        height: 13px;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                  }
            }
      }
</style>

<template>
      <div id="sydney-health">
            <section class="section hero">
                  <div class="grid-container">
                        <BackButton link="/resources" color="white"/>
                        <div id="hero-content" class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell large-5">
                                    <h1 class="white-text mb40">Sydney<sup>SM</sup><br class="hide-for-medium"> Health</h1>
                              </div>
                        </div>
                  </div>
            </section>
            <section class="section bottom-content">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb24">
                              <div class="cell large-9 first-column">
                                    <p class="navy-blue-text fs18 fw400">Sydney<sup>SM</sup> Health is an intuitive, digital mobile platform that directly connects you to your benefit information on the go — 24/7.</p>
                                    
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-top animate__animated animate__fadeIn">
                              <div class="cell medium-6">
                                    <ul>
                                          <li>See what's covered by your plan</li>
                                          <li>Get an estimate on service costs</li>
                                          <li>Find in-network doctors/specialists near you</li>
                                          <li>Access your digital ID card</li>
                                          <li>Get answers quickly with interactive chat</li>
                                    </ul>
                              </div>
                              <div class="cell medium-5 medium-offset-1">
                                    <p><a href="https://www.anthem.com/login/" class="navy-blue-text underline" target="_blank" @click="track('Link', 'Log-in - Sydney Health')">Log-in</a> with your Anthem username and password. If you aren’t registered online, create an account today.</p>
                                    <p class="fs18"><a href="https://apps.apple.com/us/app/sydney-health/id1463423283" class="navy-blue-text underline" target="_blank" @click="track('Link', 'Apple App Store - Sydney Health')">Download</a> the Sydney<sup>SM</sup> Health app today from the App Store or Google Play. </p>
                                    <div class="grid-x grid-padding-x align-top align-center">
                                          <div class="cell small-6 medium-5 mb24">
                                                <img src="~@/assets/img/sydney-iphone.png"/>
                                          </div>
                                    </div>
                                    <div class="grid-x grid-padding-x align-top align-center">
                                          <div class="cell small-6 medium-5 mb24">
                                                <a href="https://play.google.com/store/apps/details?id=com.anthem.sydney&hl=en_US&gl=US" target="_blank" @click="track('CTA', 'Google Play - Sydney Health')"><img src="~@/assets/img/google-play.png"/></a>
                                          </div>
                                          <div class="cell small-6 medium-5">
                                                <a href="https://apps.apple.com/us/app/sydney-health/id1463423283" target="_blank" @click="track('CTA', 'Apple App Store - Sydney Health')"><img src="~@/assets/img/app-store.png"/></a>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
            <NextButton link="/livehealth-online"/>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import NextButton from "../components/NextButton"
      import gaEventMixin from "../mixins/gaEventMixin"
      export default {
            name: "SHBPSydneyHealth",
            data (){
                  return{
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {
                  BackButton,
                  NextButton
            },
            mixins: [gaEventMixin]
      }
</script>

<style lang="scss">
      #sydney-health{
            .hero{
                  background-image: url("~@/assets~@/assets/img/sydneyhealth-hero-2024-lg.jpg");
                  background-position: right top;
                  background-repeat: no-repeat;
                  background-size: auto;
                  position: relative;
                  
                  #hero-content{
                        h1{
                              margin-top: 2.5rem;
                              @media only screen and (max-width:640px){
                                    margin-bottom: 0 !important;
                              }
                        }
                  }
                  @media only screen and (max-width: 640px){
                        //background-position: -400px center;
                  }
            }
      }
</style>

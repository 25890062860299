<template>
      <div id="dermatologist">
            <section class="section bottom-content">
                  <div class="grid-container">
                        <BackButton link="/livehealth-online"/>
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb24">
                              <div class="cell large-8">
                                    <h1>Connect with a dermatologist online, anytime anywhere</h1>
                              </div>
                              <div class="cell large-12">
                                    <div class="grid-x grid-padding-x">
                                          <div class="cell large-10">
                                                <p class="fs18 navy-blue-text fw400">Its easy and convenient using LiveHealth Online.</p>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb48">
                              <div class="cell medium-8">
                                    <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb24">
                                          <div class="cell large-12">
                                                <p class="fs18">Using LiveHealth Online you can visit with a dermatologist online, 24 hours a day, 7 days a week. No appointment needed. It’s a great option for care when your own doctor isn’t available, or you can’t wait weeks for an appointment. Start a visit today to get a treatment plan and a prescription if needed.</p>
                                          </div>
                                    </div>
                                    <div class="grid-x grid-padding-x align-top mb24">
                                          <div class="cell large-12">
                                                <p class="navy-blue-text fs18 fw600">Visit a dermatologist in 3 easy steps.</p>
                                          </div>
                                    </div>
                                    <div class="grid-x grid-padding-x animate__animated animate__fadeIn mb">
                                          <div class="cell medium-4">
                                                <div class="grid-x grid-padding-x mb16">
                                                      <div class="cell medium-12 text-center">
                                                            <img src="img/derm-step-1.png" width="160">
                                                      </div>
                                                </div>
                                                <div class="grid-x grid-padding-x mb24">
                                                      <div class="cell medium-12 text-center">
                                                            <p><span class="navy-blue-text fw600 text-center">Step 1:</span><br> Sign up or log into LiveHealth Online and select Dermatology to start a visit.</p>
                                                      </div>
                                                </div>
                                          </div>
                                          <div class="cell medium-4">
                                                <div class="grid-x grid-padding-x mb16">
                                                      <div class="cell medium-12 text-center">
                                                            <img src="img/derm-step-2.png" width="160">
                                                      </div>
                                                </div>
                                                <div class="grid-x grid-padding-x mb24">
                                                      <div class="cell medium-12 text-center">
                                                            <p><span class="navy-blue-text fw600">Step 2:</span><br> Describe the reason for your visit and upload any photos of the affected area.</p>
                                                      </div>
                                                </div>
                                          </div>
                                          <div class="cell medium-4">
                                                <div class="grid-x grid-padding-x mb16">
                                                      <div class="cell medium-12 text-center">
                                                            <img src="img/derm-step-3.png" width="160">
                                                      </div>
                                                </div>
                                                <div class="grid-x grid-padding-x mb24">
                                                      <div class="cell medium-12 text-center">
                                                            <p><span class="navy-blue-text fw600">Step 3:</span><br> Get a diagnosis, treatment plan and a prescription when needed.</p>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                              <div id="derm-right-sidebar" class="cell medium-3 medium-offset-1">
                                    <div class="grid-x grid-padding-x">
                                          <h3 class="navy-blue-text fw600 fs16">Commonly diagnosed and treated conditions:</h3>
                                          <ul>
                                                <li>Acne</li>
                                                <li>Athletes foot</li> 
                                                <li>Chronic hives</li> 
                                                <li>Eczema</li> 
                                                <li>Genital herpes or warts</li> 
                                                <li>Hair loss</li> 
                                                <li>Insect bites</li> 
                                                <li>Jock Itch</li> 
                                                <li>Lice</li> 
                                                <li>Nail problems</li> 
                                                <li>Psoriasis</li> 
                                                <li>Rash</li> 
                                                <li>Ring worm</li> 
                                                <li>Rosacea</li> 
                                                <li>Shingles</li>
                                          </ul>
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
            <NextButton link="/total-health"/>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import NextButton from "../components/NextButton"
      import gaEventMixin from "../mixins/gaEventMixin"
      export default {
            name: "SHBPDermatologist",
            data (){
                  return{
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {
                  BackButton,
                  NextButton
            },
            mixins: [gaEventMixin]
      }
</script>

<style lang="scss">
      #dermatologist{
            .back-btn{
                  left: 0.65rem;
            }
            .bottom-content{
                 margin-top: 68px;
                 padding: 100px 0px 80px 0px;
                 @media only screen and (max-width:640px){
                        padding: 40px 20px 80px 20px;
                  }
                 h1{
                        color: #002E58;
                       margin-top: 3rem;
                       margin-bottom: 4rem;
                       @media only screen and (max-width:640px){
                              margin-top: 4rem;
                              margin-bottom: 2rem;
                        }
                 }
                 h5{
                       margin-bottom: 0.5rem;
                 }
                 .side-content{
                        border-left:solid 4px #F05349;
                        padding-left: 16px;
                        padding: 8px 0px 8px 16px;
                        p{
                              margin-bottom: 0px !important;
                        }
                  }
                  .waiver-box{
                        background-color: rgba(105, 179, 231, 0.3);
                        padding: 24px;
                        border-radius: 20px;
                  }
           }
           .return-link{
                  position: relative;
                  padding-left: 20px;
                  &:before{
                        content: "";
                        background-image: url("~@/assets/img/arrow-left-icon.png");
                        background-size: 15px 13px;
                        width: 15px;
                        height: 13px;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                  }
            }
            #derm-right-sidebar{
                  position: relative;
                  &:before{
                        content:"";
                        position: absolute;
                        left: -40px;
                        top: 0;
                        width: 1px;
                        background-color: #000000;
                        height: 90%;
                        opacity: 0.4;
                  }
            }
      }
</style>

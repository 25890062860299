<template>
      <div id="rewards">
            <section class="section hero">
                  <div class="grid-container">
                        <BackButton link="/total-health" color="white"/>
                        <div id="hero-content" class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell large-6">
                                    <h1>Rewards for healthy living</h1>
                              </div>
                        </div>
                  </div>
            </section>
            <section class="section bottom-content">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x animate__animated animate__fadeIn mb80">
                              <div class="cell large-7 first-column">
                                    <h5 class="navy-blue-text fs16 mb8">Reduce your costs by earning points<sup>1</sup></h5>
                                    <p>With each of the Anthem Plan Options, you and your covered spouse can each earn up to 480 points (for a total of up to 960) through the <em>Be Well SHBP<sup>&reg;</sup></em> well-being program administered by Sharecare.<sup>1</sup></p>
                                    <p>These points can be redeemed as credits and applied to covered medical and pharmacy expenses.</p>
                                    <div class="grid-x grid-padding-x">
                                          <div class="cell medium-12">
                                                <div class="rewards-box">
                                                      <h5 class="new-blue-text fs16 mb8">Earning rewards is easy</h5>
                                                      <p>Once you complete certain health actions in the <em>Be Well SHBP®</em> well-being program, you will earn points that you can choose to redeem as credits. If redeemed as credits, the credits will be deposited into a spending account tied to your HMO or HRA plan. </p>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                               <div class="cell large-4 medium-offset-1">
                                    <div class="rewards-side-box">
                                          <div class="grid-x grid-padding-x mb24">
                                                <div class="cell medium-12">
                                                      <img src="img/magnify-icon.png" width="40">
                                                </div>
                                                <div class="cell medium-12">
                                                      <h6 class="baby-blue-blue-text fs24">Take</h6>
                                                      <p>the RealAge<sup>&reg;</sup> Test<sup>2</sup></p>
                                                </div>
                                          </div>
                                          <div class="grid-x grid-padding-x mb24">
                                                <div class="cell medium-12">
                                                      <img src="img/ruler-icon.png" width="30">
                                                </div>
                                                <div class="cell medium-12">
                                                      <h6 class="baby-blue-blue-text fs24">Get</h6>
                                                      <p>a biometric screening</p>
                                                </div>
                                          </div>
                                          <div class="grid-x grid-padding-x">
                                                <div class="cell medium-12">
                                                      <img src="img/whistle-icon.png" width="50">
                                                </div>
                                                <div class="cell medium-12">
                                                      <h6 class="baby-blue-blue-text fs24">Complete</h6>
                                                      <p>well-being coaching, online challenges, and/or preventive screenings</p>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x mb48">
                              <div class="cell large-12">
                                    <p class="fs12">1 Completing your well-being incentive actions with Sharecare will earn you points. You can choose to redeem these points for well-being incentive credits to use on covered medical and pharmacy expenses or the Visa Reward Card option. 
Visit BeWellSHBP.com for more information. 2 Points cannot be awarded until completion of the RealAge Test.</p>
                              </div>
                        </div>
                  </div>
            </section>
            <NextButton link="/plan-credits"/>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import NextButton from "../components/NextButton"
      export default {
            name: "SHBPRewards",
            data (){
                  return{
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {
                  BackButton,
                  NextButton
            }
      }
</script>

<style lang="scss">
      #rewards{
            .hero{
                  background-image: url("~@/assets~@/assets/img/rewards-hero-2024-lg.jpg");
                  background-position: right top;
                  background-repeat: no-repeat;
                  background-size: auto;
                  #hero-content{
                        h1{
                              color: #ffffff;
                              margin-bottom: 4.5rem;
                              @media only screen and (max-width:640px){
                                    margin-bottom: 0 !important;
                              }
                        }
                  }
            }
            .rewards-box{
                  background-color: #E1EDFF;
                  padding: 24px;
                  border-radius: 20px;
                  margin-top: 120px;
                  @media only screen and (max-width:640px){
                        margin-bottom: 24px !important;
                        margin-top: 24px;
                  }
            }
            .rewards-side-box{
                  background-color: #1355E9;
                  padding: 40px;
                  border-radius: 24px;
                  img{
                        margin-bottom: 8px;
                        filter:  brightness(0) invert(1);
                  }
                  h6{
                        margin-bottom: 0px;
                  }
                  p{
                        color: #ffffff;
                  }
            }
      }
</style>

<template>
      <div id="choose-plan">
            <section class="section bottom-content">
                  <div class="grid-container">
                        <BackButton link="/checklist"/>
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb32">
                              <div class="cell large-8">
                                    <h1>Choose a plan that's all about you</h1>
                                    <div class="grid-x grid-padding-x">
                                          <div class="cell large-12">
                                                <p class="fs18 navy-blue-text fw400">What's the difference between Anthem's HMO and HRA Plan Options?</p>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell large-8 buttons mb24">
                                    <div class="button-group no-gaps">
                                          <button class="button hmo" @click="planSelection='HMO'" v-bind:class="(planSelection=='HMO') ? 'active' : ''">HMO</button>
                                          <button class="button hra" @click="planSelection='HRA'" v-bind:class="(planSelection=='HRA') ? 'active' : ''">HRA</button>
                                    </div>
                              </div>
                        </div>
                        <div v-if="planSelection=='HMO'" class="grid-x grid-padding-x align-middle hmo-plans animate__animated animate__fadeIn">
                              <div class="cell medium-8 plans">
                                    <div class="grid-x grid-padding-x align-middle">
                                          <div class="cell medium-6">
                                                <div class="plan-description plan">
                                                      <p>Anthem's HMO, or Health Maintenance Organization, is a Plan Option that offers access to in-network providers only, who have agreed to accept negotiated rates for covered services provided. It offers set co-pays for certain covered services and doesn't require a referral to see a specialist. </p>
                                                </div>
                                          </div>
                                          <div class="cell medium-6">
                                                <div class="plan-choice plan">
                                                      <p>An HMO might be the best choice if:</p>
                                                      <ul>
                                                            <li>Your doctors are in-network</li>
                                                            <li>You prefer to pay set co-pays for certain services</li>
                                                      </ul>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div v-else-if="planSelection == 'HRA'" class="grid-x grid-padding-x align-middle hra-plans animate__animated animate__fadeIn">
                              <div class="cell medium-12 plans">
                                    <div class="grid-x grid-padding-x align-middle">
                                          <div class="cell medium-8">
                                                <div class="plan-description plan">
                                                      <p>Anthem's HRA, or Health Reimbursement Arrangement, offers the same benefits you would receive from a typical health plan, plus healthcare credits to help offset your out-of-pocket expenses. SHBP sets up an HRA account for you and deposits base credits determined by the HRA Plan Option you choose. </p>
                                                      <h5 class="mt24 fw600 mb8">HRA Gold, Silver, and Bronze</h5>
                                                      <p>Anthem's HRA plans are designed to accommodate a range of financial and health goals. There are three levels of Anthem HRA Plan Options that have different base credits, deductibles, co-insurance levels and out-of-pocket maximums.</p>
                                                </div>
                                          </div>
                                          <div class="cell medium-4">
                                                <div class="plan-choice plan">
                                                      <p>An HRA might be best for you if:</p>
                                                      <ul>
                                                            <li>You want the option for both in-and out-of-network coverage</li>
                                                            <li>You want a base fund of credits from SHBP to help offset healthcare costs</li>
                                                      </ul>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
            <NextButton link="/plan-options"/>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import NextButton from "../components/NextButton"
      export default {
            name: "SHBPChoosePlan",
            data (){
                  return{
                        planSelection: "HMO"
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {
                  BackButton,
                  NextButton
            }
      }
</script>

<style lang="scss">
      #choose-plan{
            .back-btn{
                  left: 0.65rem;
            }
           .bottom-content{
                 margin-top: 68px;
                 padding: 100px 0px 80px 0px;
                 @media only screen and (max-width:640px){
                        padding: 40px 20px 80px 20px;
                  }
                 h1{
                        color: #002E58;
                       margin-top: 3rem;
                       margin-bottom: 4rem;
                       @media only screen and (max-width:640px){
                              margin-top: 4rem;
                              margin-bottom: 2rem;
                        }
                 }
                 .buttons{
                       .button{
                             font-size: 1.125rem;
                             font-weight: 600;
                             background-color: #E1EDFF;
                             color: rgba(50, 56, 61, 0.4);
                             &.hmo{
                                   border-radius: 20px 0px 0px 20px;
                             }
                             &.hra{
                                   border-radius: 0px 20px 20px 0px;
                                   &.active{
                                          background-color: #0E63AF;
                                    }
                             }
                             &:hover, &.active{
                                   background-color: #1355E9;
                                   color: #ffffff;
                             }
                       }
                 }
                 .plans{
                       .plan{
                             //border-radius: 20px;
                             padding: 32px;
                             min-height: 325px;
                             &.plan-description{
                                   background-color: #E1EDFF;
                                   margin-bottom: 1rem;
                                   h5{
                                         font-size: 1.25rem;
                                   }
                             }
                             &.plan-choice{
                                   background-color: #1355E9;
                                   p{
                                          color: #ffffff;
                                          font-weight: 600;
                                          margin-bottom: 1.125rem;
                                          margin-top: 1.5rem;
                                    }
                             }
                             p{
                                   margin-bottom: 0px !important;
                             }
                             ul{
                                   li{
                                         color:#ffffff;
                                         font-weight: 600;
                                   }
                             }
                       }
                 }
           }
      }
</style>

<template>
      <div id="one-more-question">
            <section class="section hero">
                  <div class="grid-container">
                        <BackButton link="/benefits-current" color="white"/>
                        <div id="hero-content" class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell medium-8">
                                    <h1>One more question</h1>
                              </div>
                        </div>
                  </div>
            </section>
            <section class="section bottom-content">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn mb24">
                              <div class="cell medium-5">
                                    <h3>Now that you've reviewed the 2025 Plan Options, would you like to keep your current plan — or do you want to make a change?</h3>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn mb8">
                              <div class="cell medium-5">
                                    <div class="grid-x grid-padding-x">
                                          <label class="radio cell auto">
                                                <div class="grid-x grid-padding-x">
                                                      <div class="cell shrink">
                                                            <input type="radio" value="Yes" v-model="keepPlan"/>
                                                      </div>
                                                      <div class="cell auto">
                                                            <span>I want to keep the same plan I had last year.</span>
                                                      </div>
                                                </div>
                                          </label>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-center animate__animated animate__fadeIn">
                              <div class="cell medium-5">
                                    <div class="grid-x grid-padding-x">
                                          <label class="radio cell auto">
                                                <div class="grid-x grid-padding-x">
                                                      <div class="cell shrink">
                                                            <input type="radio" value="No" v-model="keepPlan"/>
                                                      </div>
                                                      <div class="cell auto">
                                                            <span>I'd like to make a change in my Anthem Plan Options.</span>
                                                      </div>
                                                </div>
                                          </label>
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
            <section id="check-btn-bottom">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x">
                              <div class="cell medium-12">
                                    <button class="button check-btn large" @click="storeData()">Next</button>
                              </div>
                        </div>
                  </div>
            </section>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import gaEventMixin from "../mixins/gaEventMixin"
      export default {
            name: "SHBPOneMoreQuestionCurrent",
            data (){
                  return{
                        keepPlan: this.$store.state.keepPlan,
                  }
            },
            methods:{
                  storeData(){
                        const keepPlan = this.keepPlan
                        if((!keepPlan) ){
                              let message = 'Please make a choice below.'
                              this.$swal({
                                    html: message,
                                    showCancelButton: false
                              })
                        }
                        else{
                              //const routes = this.$store.state.routeName
                              this.$store.commit('changeKeepPlan', keepPlan)
                              this.track("Choice", keepPlan + " Coverage")
                              if(keepPlan=="No") this.$router.push('/plan-options-current')
                              else this.$router.push('/summary-current')
                              localStorage.setItem("antKeepPlan", keepPlan)
                              //localStorage.setItem("antRouteNames", JSON.stringify(routes))
                        }
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {BackButton},
            mixins: [gaEventMixin]
      }
</script>

<style lang="scss">
      #one-more-question{
            .hero{
                  background-image: none;
                  h1, p{
                        color: #ffffff !important;
                  }
                  #hero-content{
                        h1{
                              margin-bottom: 8rem;
                              @media only screen and (max-width:640px){
                                    margin-bottom: 120px;
                              }
                        }
                  }
            }
            h3{
                  color: #32383D;
                  font-size: 1.125rem;
            }
      }
</style>

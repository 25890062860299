<template>
      <div id="summary-current">
            <section class="section hero">
                  <div class="grid-container">
                        <BackButton link="/one-more-question-current" color="white"/>
                        <div id="hero-content" class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell medium-8">
                                    <h1 class="mb40">Congratulations,<br> you're done</h1>
                                    <p>Your plan will automatically roll over for 2025.</p>
                              </div>
                              <div class="cell medium-4">
                                    <p class="mb40">Download a copy of the 2025 Open Enrollment Guide, which outlines the information presented in this interactive guide.</p>
                                    <router-link to="/" class="button white-btn" @click="track('CTA', 'Done - Summary Current')">Done</router-link>
                                    <a :href="planDownload" target="_blank" class="button white-btn" @click="track('CTA', 'Download OE Guide - Summary Current')">Download</a>
                              </div>
                        </div>
                  </div>
            </section>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import gaEventMixin from "../mixins/gaEventMixin"
      export default {
            name: "SHBPSummaryCurrent",
            data (){
                  return{
                        planSelect: this.$store.state.planSelect,
                        getStartedURL:"https://myshbpga.adp.com/",
                        planDownload:"",
                  }
            },
            methods:{
                  download(){
                        let planSelect = this.planSelect
                        if(planSelect=="HMO") this.planDownload = "/docs/OE-Guide-25.pdf"
                        else if(planSelect=="HRA Gold") this.planDownload = "/docs/OE-Guide-25.pdf"
                        else if(planSelect=="HRA Silver") this.planDownload =  "/docs/OE-Guide-25.pdf"
                        else if(planSelect=="HRA Bronze") this.planDownload =  "/docs/OE-Guide-25.pdf"
                        else this.planDownload =  "/docs/OE-Guide-25.pdf"
                  }
            },
            beforeMount(){
                  this.download()
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {
                  BackButton
            },
            mixins: [gaEventMixin]
      }
</script>

<style lang="scss">
      #summary-current{
            .hero{
                  background-image: none;
                  min-height: 100vh;
                  h1, p{
                        color: #ffffff !important;
                  }
                  .mb40{
                        margin-bottom: 40px !important;
                  }
                  .white-btn{
                        display: block;
                        background-color: #ffffff;
                        border-radius: 20px; 
                        color: #0A357C !important;
                        font-weight: 700;
                        width: 100%;
                        padding: 1rem;
                        transition: opacity 0.2s ease-in-out 0s;
                        &:hover{
                              opacity: 0.8;
                        }
                  }
                  #hero-content{
                        min-height: 60vh;
                        h1{
                              @media only screen and (max-width:640px){
                                    margin-top: 1.5rem;
                              }
                        }
                  }
            }
            h3{
                  color: #32383D;
                  font-size: 1.25rem;
                  font-weight: 600;
            }
      }
</style>

<template>
      <div id="overview">
            <section class="section bottom-content">
                  <div class="grid-container">
                        <BackButton link="/welcome-back-current"/>
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb32">
                              <div class="cell large-10">
                                    <h1>Welcome back, {{ firstName }}</h1>
                              </div>
                              <div class="cell large-12">
                                    <div class="grid-x grid-padding-x">
                                          <div class="cell large-10">
                                                <p>This year, the Open Enrollment selection period is <br/><a href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20241015%2F20241108&details=This%20year%2C%20the%20Open%20Enrollment%20selection%20period%20is%20October%2015%2C%202024%20-%0ANovember%208%2C%202024.%20&text=Anthem%20Open%20Enrollment" class="navy-blue-text underline" target="_blank">October 15 - November 8, 2024</a></p>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-middle">
                              <div class="cell medium-6">
                                    <div class="overview-choice-box mb32">
                                          <p class="bullet">There are no new changes in your plan for 2025. Continue to review your Anthem plan checklist before enrolling for 2025.</p>
                                    </div>
                              </div>
                              <div class="cell medium-5 medium-offset-1">
                                    <p>The estimated length of your experience on this site will be: <span class="navy-blue-text">6 minutes</span></p>
                              </div>
                        </div>
                  </div>
            </section>
            <NextButton link="/checklist-current"/>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import NextButton from "../components/NextButton"
      export default {
            name: "SHBPOverview",
            data (){
                  return{
                        firstName: this.$store.state.firstName,
                  }
            },
            components: {
                  BackButton,
                  NextButton
            }
      }
</script>

<style lang="scss">
      #overview{
            .back-btn{
                  left: 0.65rem;
            }
           .bottom-content{
                 margin-top: 68px;
                 padding: 100px 0px 80px 0px;
                 @media only screen and (max-width:640px){
                        padding: 40px 20px 80px 20px;
                  }
                 h1{
                        color: #002E58;
                        margin-top: 3rem;
                        margin-bottom: 4rem;
                        @media only screen and (max-width:640px){
                              margin-top: 4rem;
                              margin-bottom: 2rem;
                        }
                 }
                 .overview-choice-box{
                        background-color: #E1EDFF;
                        border-radius: 20px;
                        padding: 76px;
                        min-height: 300px;
                        p{
                              color: #000000;
                              font-weight: 500;
                              margin-bottom: 0 !important;
                              margin-top: 0;
                              font-size: 1.25rem;
                        }
                        ul{
                              li{
                                    color:#ffffff;
                                    font-weight: 600;
                              }
                        }
                 }
           }
      }
</style>

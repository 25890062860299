<template>
      <div id="resources">
            <section class="section hero">
                  <div class="grid-container">
                        <BackButton link="/compare" color="white"/>
                        <div id="hero-content" class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell large-5">
                                    <h1>Online<br class="hide-for-medium"> resources</h1>
                              </div>
                        </div>
                  </div>
            </section>
            <section class="section bottom-content">
                  <div class="grid-container">
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb24">
                              <div class="cell large-9 first-column">
                                    <p class="fs18 navy-blue-text fw400">Available at no additional cost, Anthem's online tools offer you a safe, secure, personalized and interactive experience built around your health and benefits.</p>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-top animate__animated animate__fadeIn">
                              <div class="cell medium-3 mb24">
                                    <img src="img/clipboard-new-icon.png" class="mb8" width="41"/>
                                    <h5 class="navy-blue-text fs18 mb8">Compare Plan Options</h5>
                                    <p>The Benefits at a Glance flyer shows you a comparison of which Anthem plan might work best for you and your family.</p>
                              </div>
                              <div class="cell medium-3 mb24">
                                    <img src="img/nurse-new-icon.png" class="mb8" width="32"/>
                                    <h5 class="navy-blue-text fs18 mb8">Chat online</h5>
                                    <p>Chat with an Anthem representative from the convenience of your home or office.</p>
                              </div>
                              <div class="cell medium-3 mb24">
                                    <img src="img/stethoscope-new-icon.png" class="mb8" width="40"/>
                                    <h5 class="navy-blue-text fs18 mb8">Find care</h5>
                                    <p>Available with both the HMO and HRA Plan Options, the Find Care tool helps you locate an in-network doctor or facility near you.</p>
                              </div>
                              <div class="cell medium-3 mb24">
                                    <img src="img/heart-new-icon.png" class="mb8" width="50"/>
                                    <h5 class="navy-blue-text fs18 mb8">Manage your health</h5>
                                    <p>Check the status of a claim, review medical benefits, chat with a Member Services representative, view your member ID card or take advantage of discounts on health products — available when you register online at <a href="https://www.anthem.com/shbp" class="navy-blue-text underline" target="_blank" @click="track('Link', 'anthem.com/shbp - Resources')">anthem.com/shbp</a>.</p>
                              </div>
                        </div>
                  </div>
            </section>
            <NextButton link="/sydney-health"/>
      </div>
</template>
<script>
      import BackButton from "../components/BackButton"
      import NextButton from "../components/NextButton"
      import gaEventMixin from "../mixins/gaEventMixin"
      export default {
            name: "SHBPResources",
            data (){
                  return{
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {
                  BackButton,
                  NextButton
            },
            mixins: [gaEventMixin]
      }
</script>

<style lang="scss">
      #resources{
            .hero{
                  background-image: url("~@/assets~@/assets/img/resources-hero-2024-lg.jpg");
                  background-position: right top;
                  background-repeat: no-repeat;
                  background-size: auto;
                  #hero-content{
                        h1{
                              color: #ffffff;
                              margin-bottom: 4.5rem;
                              @media only screen and (max-width:640px){
                                    margin-bottom: 0;
                              }
                        }
                  }
                  @media only screen and (max-width: 640px){
                        //background-position: -440px center;
                  }
            }
            img{
                  filter: invert(53%) sepia(87%) saturate(3205%) hue-rotate(330deg) brightness(94%) contrast(100%);
            }
      }
</style>

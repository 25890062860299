export default {
      methods: {
            track(cat, label) {
                  this.$gtag.event('Click', {
                        'event_category': cat,
                        'event_label': label,
                        'value': 1
                  })
            }
      }
}
<template>
      <div id="summary">
            <section class="section hero">
                  <div class="grid-container">
                        <BackButton link="/benefits" color="white"/>
                        <div id="hero-content" class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn">
                              <div class="cell medium-6">
                                    <h1 class="mb40">You're almost done</h1>
                                    <p>Thanks for taking the time to go through these steps to determine the best plan for you. </p>
                                    <div class="grid-x grid-padding-x align-middle mt40 mb24">
                                          <div class="cell small-6 shrink">
                                                <p>This is your suggested plan: </p>
                                          </div>
                                          <div class="cell small-6 medium-6">
                                                <p class="fw700 fs32 text-left lh1-1">{{ planSelect }}</p>
                                          </div>
                                    </div>
                              </div>
                              <div class="cell medium-4 medium-offset-2">
                                    <p class="mb40">Now that you know your suggested Anthem plan, you can download the OE Guide as a reminder, or go directly to <a href="https://myshbpga.adp.com/" class="white-text underline" target="_blank" @click="track('Link','myshbpga.adp.com - Summary')">https://myshbpga.adp.com/</a> to enroll during this year's Open Enrollment selection period, <span class="fw600">October 15 through November 8, 2024.</span></p>
                                    <button class="button white-btn"  @click="checkDate()">Enroll</button>
                                    <a :href="planDownload" target="_blank" class="button white-btn" @click="track('CTA','Download OE Guide - Summary')">Download</a>
                              </div>
                        </div>
                  </div>
            </section>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import gaEventMixin from "../mixins/gaEventMixin"
      export default {
            name: "SHBPSummary",
            data (){
                  return{
                        planSelect: this.$store.state.planSelect,
                        linkCheck: "My VAR Test",
                        getStartedURL:"https://myshbpga.adp.com/",
                        planDownload:"",
                        popMessage: "<h5 style='font-weight:600;font-size: 1.125rem;margin-bottom:8px !important;'>Open Enrollment starts October 15, 2024</h5> <p style='margin-bottom:8px !important;'>You can't enroll in your suggested plan yet, but go to <a href='https://myshbpga.adp.com' target='_blank' id='popLink' @click='method'>myshbpga.adp.com</a>  to review your suggested plan costs. You'll also find additional information and valuable resources about how to get the most out of your plan options. Be sure to come back, starting October 15, 2024 to enroll in your suggested plan.</p>"
                  }
            },
            methods: {
                  checkDate(){
                        let enrollDate = "2024-10-15",
                              currentDate = new Date()
                        enrollDate = new Date(enrollDate)
                        if(enrollDate > currentDate){
                              this.$swal({
                                    html: this.popMessage,
                                    showCancelButton: false
                              })
                              this.track("CTA", "No Open Enrollment - Summary")
                        }
                        else{
                              this.track("CTA", "Yes Open Enrollment - Summary")
                              window.open( this.getStartedURL, "_blank")
                        }
                  },
                  download(){
                        let planSelect = this.planSelect
                        if(planSelect=="HMO") this.planDownload = "/docs/OE-Guide-25.pdf"
                        else if(planSelect=="HRA Gold") this.planDownload = "/docs/OE-Guide-25.pdf"
                        else if(planSelect=="HRA Silver") this.planDownload =  "/docs/OE-Guide-25.pdf"
                        else if(planSelect=="HRA Bronze") this.planDownload =  "/docs/OE-Guide-25.pdf"
                        else this.planDownload =  "/docs/OE-Guide-25.pdf"
                  },
                  someMethod(e) {
                        e.preventDefault();
                        this.track("Link", "myshbpga.adp.com - Pop Up Window - Summary")
                        window.open( this.getStartedURL, "_blank")
                  }
            },
            beforeMount(){
                  this.download()
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {
                  BackButton
            },
            mixins: [gaEventMixin]
      }
</script>

<style lang="scss">
      #summary{
            .hero{
                  background-image: none;
                  min-height: 100vh;
                  h1, p{
                        color: #ffffff !important;
                  }
                  .mb40{
                        margin-bottom: 40px !important;
                  }
                  .white-btn{
                        display: block;
                        background-color: #ffffff;
                        border-radius: 20px; 
                        color: #005097 !important;
                        font-weight: 600;
                        width: 100%;
                        padding: 1rem;
                        transition: opacity 0.2s ease-in-out 0s;
                        &:hover{
                              opacity: 0.8;
                        }
                  }
                  #hero-content{
                        min-height: 60vh;
                        h1{
                              @media only screen and (max-width:640px){
                                    margin-top: 1.5rem;
                              }
                        }
                  }
            }
            h3{
                  color: #32383D;
                  font-size: 1.25rem;
                  font-weight: 600;
            }
      }
</style>

<template>
      <div id="plan-credits">
            <section class="section bottom-content">
                  <div class="grid-container">
                        <BackButton link="/rewards"/>
                        <div class="grid-x grid-padding-x align-middle animate__animated animate__fadeIn mb24">
                              <div class="cell large-10">
                                    <h1>Credits by plan</h1>
                              </div>
                              <div class="cell large-12">
                                    <div class="grid-x grid-padding-x">
                                          <div class="cell large-10">
                                                <p class="fs18 navy-blue-text fw400">Here’s a breakdown of how many credits you can<br class="show-for-medium"/> accumulate per plan and according to your coverage.</p>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div class="grid-x grid-padding-x align-bottom">
                              <div class="cell medium-12">
                                    <div class="grid-x grid-padding-x mb40 align-bottom">
                                          <div class="cell shrink">
                                                <p class="mb0">Choose coverage:</p>
                                          </div>
                                          <div class="cell shrink">
                                                <select class="mb0" v-model="planSelection">
                                                      <option v-for="option in planOptions" v-bind:value="option.value" :key="option.value">
                                                            {{ option.text }}
                                                      </option>
                                                </select>
                                          </div>
                                    </div>
                                    <div class="grid-x grid-padding-x mb8">
                                          <div class="cell medium-12 table-container">
                                                <table v-if="planSelection == 'You'">
                                                      <thead>
                                                            <tr>
                                                                  <th></th>
                                                                  <th width="200" class="navy-blue-text">HMO</th>
                                                                  <th width="200" class="navy-blue-text">HRA<br/>Gold</th>
                                                                  <th width="200" class="navy-blue-text">HRA<br/>Silver</th>
                                                                  <th width="200" class="navy-blue-text">HRA <br/>Bronze</th>
                                                            </tr>
                                                      </thead>
                                                      <tbody>
                                                            <tr>
                                                                  <td class="first hmo-bg-light-blue">Credits contributed by SHBP</td>
                                                                  <td class="hmo-bg-blue">0</td>
                                                                  <td class="hmo-bg-light-blue">400</td>
                                                                  <td class="hmo-bg-blue">200</td>
                                                                  <td class="hmo-bg-light-blue">100</td>
                                                            </tr>
                                                            <tr>
                                                                  <td class="first hmo-bg-light-blue">Credits you can earn</td>
                                                                  <td class="hmo-bg-blue">480</td>
                                                                  <td class="hmo-bg-light-blue">480</td>
                                                                  <td class="hmo-bg-blue">480</td>
                                                                  <td class="hmo-bg-light-blue">480</td>
                                                            </tr>
                                                            <tr>
                                                                  <td class="first fw600 hmo-bg-light-blue">Total possible credits</td>
                                                                  <td class="fw600 hmo-bg-blue">480</td>
                                                                  <td class="fw600 hmo-bg-light-blue">880</td>
                                                                  <td class="fw600 hmo-bg-blue">680</td>
                                                                  <td class="fw600 hmo-bg-light-blue">580</td>
                                                            </tr>
                                                      </tbody>
                                                </table>

                                                <table v-else-if="planSelection == 'You-Spouse'">
                                                      <thead>
                                                            <tr>
                                                                  <th></th>
                                                                  <th width="200" class="navy-blue-text">HMO</th>
                                                                  <th width="200" class="navy-blue-text">HRA<br/>Gold</th>
                                                                  <th width="200" class="navy-blue-text">HRA<br/>Silver</th>
                                                                  <th width="200" class="navy-blue-text">HRA<br/>Bronze</th>
                                                            </tr>
                                                      </thead>
                                                      <tbody>
                                                            <tr>
                                                                  <td class="first hmo-bg-light-blue">Credits contributed by SHBP</td>
                                                                  <td class="hmo-bg-blue">0</td>
                                                                  <td class="hmo-bg-light-blue">600</td>
                                                                  <td class="hmo-bg-blue">300</td>
                                                                  <td class="hmo-bg-light-blue">150</td>
                                                            </tr>
                                                            <tr>
                                                                  <td class="first hmo-bg-light-blue">Credits you can earn</td>
                                                                  <td class="hmo-bg-blue">960</td>
                                                                  <td class="hmo-bg-light-blue">960</td>
                                                                  <td class="hmo-bg-blue">960</td>
                                                                  <td class="hmo-bg-light-blue">960</td>
                                                            </tr>
                                                            <tr>
                                                                  <td class="first fw600 hmo-bg-light-blue">Total possible credits</td>
                                                                  <td class="fw600 hmo-bg-blue">960</td>
                                                                  <td class="fw600 hmo-bg-light-blue">1,560</td>
                                                                  <td class="fw600 hmo-bg-blue">1,260</td>
                                                                  <td class="fw600 hmo-bg-light-blue">1,110</td>
                                                            </tr>
                                                      </tbody>
                                                </table>

                                                <table v-if="planSelection == 'You-Child(ren)'">
                                                      <thead>
                                                            <tr>
                                                                  <th></th>
                                                                  <th width="200" class="navy-blue-text">HMO</th>
                                                                  <th width="200" class="navy-blue-text">HRA<br/>Gold</th>
                                                                  <th width="200" class="navy-blue-text">HRA<br/>Silver</th>
                                                                  <th width="200" class="navy-blue-text">HRA <br/>Bronze</th>
                                                            </tr>
                                                      </thead>
                                                      <tbody>
                                                            <tr>
                                                                  <td class="first hmo-bg-light-blue">Credits contributed by SHBP</td>
                                                                  <td class="hmo-bg-blue">0</td>
                                                                  <td class="hmo-bg-light-blue">600</td>
                                                                  <td class="hmo-bg-blue">300</td>
                                                                  <td class="hmo-bg-light-blue">150</td>
                                                            </tr>
                                                            <tr>
                                                                  <td class="first hmo-bg-light-blue">Credits you can earn</td>
                                                                  <td class="hmo-bg-blue">480</td>
                                                                  <td class="hmo-bg-light-blue">480</td>
                                                                  <td class="hmo-bg-blue">480</td>
                                                                  <td class="hmo-bg-light-blue">480</td>
                                                            </tr>
                                                            <tr>
                                                                  <td class="first fw600 hmo-bg-light-blue">Total possible credits</td>
                                                                  <td class="fw600 hmo-bg-blue">480</td>
                                                                  <td class="fw600 hmo-bg-light-blue">1,080</td>
                                                                  <td class="fw600 hmo-bg-blue">780</td>
                                                                  <td class="fw600 hmo-bg-light-blue">630</td>
                                                            </tr>
                                                      </tbody>
                                                </table>

                                                <table v-else-if="planSelection == 'You-Family'">
                                                      <thead>
                                                            <tr>
                                                                  <th></th>
                                                                  <th width="200" class="navy-blue-text">HMO</th>
                                                                  <th width="200" class="navy-blue-text">HRA<br/>Gold</th>
                                                                  <th width="200" class="navy-blue-text">HRA<br/>Silver</th>
                                                                  <th width="200" class="navy-blue-text">HRA<br/>Bronze</th>
                                                            </tr>
                                                      </thead>
                                                      <tbody>
                                                            <tr>
                                                                  <td class="first hmo-bg-light-blue">Credits contributed by SHBP</td>
                                                                  <td class="hmo-bg-blue">0</td>
                                                                  <td class="hmo-bg-light-blue">800</td>
                                                                  <td class="hmo-bg-blue">400</td>
                                                                  <td class="hmo-bg-light-blue">200</td>
                                                            </tr>
                                                            <tr>
                                                                  <td class="first hmo-bg-light-blue">Credits you can earn</td>
                                                                  <td class="hmo-bg-blue">960</td>
                                                                  <td class="hmo-bg-light-blue">960</td>
                                                                  <td class="hmo-bg-blue">960</td>
                                                                  <td class="hmo-bg-light-blue">960</td>
                                                            </tr>
                                                            <tr>
                                                                  <td class="first fw600 hmo-bg-light-blue">Total possible credits</td>
                                                                  <td class="fw600 hmo-bg-blue">960</td>
                                                                  <td class="fw600 hmo-bg-light-blue">1,760</td>
                                                                  <td class="fw600 hmo-bg-blue">1,360</td>
                                                                  <td class="fw600 hmo-bg-light-blue">1,160</td>
                                                            </tr>
                                                      </tbody>
                                                </table>
                                          </div>
                                    </div>
                                    <div class="grid-x grid-padding-x mt40">
                                          <div class="cell medium-12">
                                                <ul>
                                                      <li class="unused">Unused credits will roll over to the 2025 plan year, regardless of which SHBP Commercial (active non-MA) Plan Option you choose.</li>
                                                </ul>
                                          </div>
                                    </div>
                                    
                              </div>
                        </div>
                  </div>
            </section>
            <NextButton link="/benefits"/>
      </div>
</template>

<script>
      import BackButton from "../components/BackButton"
      import NextButton from "../components/NextButton"
      export default {
            name: "SHBPPlanCredits",
            data (){
                  return{
                        firstName: this.$store.state.firstName,
                        planSelection: (!this.$store.state.coverage || this.$store.state.coverage=="") ? "You" : this.$store.state.coverage,
                        planOptions: [
                              { text: 'You', value: 'You' },
                              { text: 'You + Spouse', value: 'You-Spouse' },
                              { text: 'You + Child(ren)', value: 'You-Child(ren)' },
                              { text: 'You + Family', value: 'You-Family' }
                        ]
                  }
            },
            mounted() {
                  window.scrollTo(0, 0)
            },
            components: {
                  BackButton,
                  NextButton
            }
      }
</script>

<style lang="scss">
      #plan-credits{
            .back-btn{
                  left: 0.65rem;
            }
            .bottom-content{
                 margin-top: 68px;
                 padding: 100px 0px 80px 0px;
                 @media only screen and (max-width:640px){
                        padding: 40px 20px 80px 20px;
                  }
                 h1{
                        color: #002E58;
                       margin-top: 3rem;
                       margin-bottom: 4rem;
                       @media only screen and (max-width:640px){
                              margin-top: 4rem;
                              margin-bottom: 2rem;
                        }
                 }
           }
            .mb0{
                  margin-bottom: 0px !important;
            }
            select{
                  background-color: #F1F5F6;
                  border: solid 2px #0079C2;
                  border-radius: 20px;
                  transition: all 0.2s ease-in-out 0s;
                  color:  #000000;
                  font-weight: 400;
                  font-size: 1.125rem;
                  padding-left:1rem;
                  cursor: pointer;
                  &:focus{
                        outline: 0;
                        box-shadow: 0 0 5px rgba(0,121,194,0.2);
                        border: solid 2px rgba(0, 121, 194, 0.7);
                  }
            }
            table{
                  --animate-duration: 0.2s;
            }
            thead{
                  background-color: #ffffff;
                  th{
                        text-align: center;
                        color:#ffffff;
                        font-size: 0.875rem;
                        padding: 0.5rem;
                        border: none;
                        font-weight: 700;
                        line-height: 1.2;
                  }
            }
            tbody, tfoot, thead{
                  border:none;
            }
            tbody tr:nth-child(odd){
                  background: none;
            }
            tbody tr:nth-child(even) {
                  border-bottom: 0;
                  background-color: inherit;
            }
            tbody{
                  td{
                        padding: 2rem 1rem;
                        text-align: center;
                        &.first{
                              text-align: left;
                        }
                        &.white-bg{
                              background-color: #ffffff;
                        }
                  }
                  tr{
                        &.row-border-top{
                              border-top: solid 1px #A4B6BE;
                        }
                  }
            }
            .hmo-bg-blue{
                  background-color: #E1EDFF;
                  border-bottom: none;
            }
            .hmo-bg-light-blue{
                  background-color: rgba(225, 237, 255, 0.5);
                  border-bottom: none;
            }
            ul{
                  list-style-type: none;
                  margin: 0px;
                  li{
                        &.unused{
                              padding-left:32px;
                              position: relative;
                              &:before{
                                    content:"";
                                    background: url('~@/assets/img/unused-arrow-icon.png') no-repeat left top;
                                    filter: brightness(0) saturate(100%) invert(37%) sepia(47%) saturate(617%) hue-rotate(318deg) brightness(126%) contrast(109%);
                                    position: absolute;
                                    background-size: 25px;
                                    width:25px;
                                    height:25px;
                                    left: 0;
                                    top:0;
                              }
                        }
                  }
            }
      }
</style>
